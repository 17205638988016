export let QaGameLinks = {
    landing: () => `/sl/piedalies-un-laime`,

    play: () => `/qa-game/play`,

    results: (totalQuestions, correctAnswers, prizeId, isPityPrize, promoCodeValue, promoCodeDiscountPercent, prizeName, prizeUsage) => {
        let url = new URL('/qa-game/results', window.CONFIG.FRONTEND_BASE_URL)
        url.searchParams.append('tq', totalQuestions);
        url.searchParams.append('ca', correctAnswers);
        url.searchParams.append('pid', prizeId);
        url.searchParams.append('pp', isPityPrize);
        url.searchParams.append('pcv', promoCodeValue);
        url.searchParams.append('pcdv', promoCodeDiscountPercent);
        url.searchParams.append('pn', encodeURIComponent(prizeName));
        url.searchParams.append('pu', encodeURIComponent(prizeUsage));

        return url.toString().replace(window.CONFIG.FRONTEND_BASE_URL, "")
    }
}
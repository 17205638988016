export let parseI18nLink = (link) => {
    if (link instanceof Object) {
        link = link.url
    }

    if (!link.startsWith("$")) {
        return link
    }

    if (link.startsWith("$")) {
        let routeId
        let params = {}

        let match = link.match(/^\$(.*){(.*)}/);
        if (match) {
            routeId = match[1]
            match[2].split(";").forEach(pair => {
                let [key, value] = pair.split("=")
                params[key] = value
            })
        } else {
            routeId = link.substring(1)
        }

        return {id: routeId, params}
    }
}

import React from "react";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {hideStickyTopBanner} from "@commons/uinew/component/marketing/stickybanner/redux";

/**
 * Banner that shows on every refresh
 */
let StickyTopBanner = ({href, messageId, hideStickyTopBanner, stickyBanner}) => {
    let onClose = () => {
        hideStickyTopBanner()
    }

    return stickyBanner.show && <div className="bg-new-green px-md-5 py-3" style={{
        // Toasts are on 9999
        zIndex: 9998
    }}>
        <div className={"container d-flex align-items-center"}>
            <div className={"fw-medium"}>
                <LocalizedLink to={href} className={"text-light"}>
                    <FormattedMessage id={messageId} values={{
                        i: chunk => <i>{chunk}</i>
                    }}/>
                </LocalizedLink>
            </div>
            <div>
                <button type="button" className="btn btn-text btn-icon text-light" onClick={onClose}>
                    <i className="ai-cross-alt"/>
                </button>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return {
        stickyBanner: state.stickyBanner
    }
};

const mapDispatchToProps = {hideStickyTopBanner};

export default connect(mapStateToProps, mapDispatchToProps)(StickyTopBanner)
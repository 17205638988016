import {FullWidthSection1} from "@commons/ui/component/Section";
import {FormattedMessage} from "react-intl";
import React from "react";
import {YouTubeVideosCarousel} from "@commons/uinew/domaincomponent/videosCarousel";

let VideoSwiper = ({blok}) => {
    return <FullWidthSection1 className={`${blok.Background}`}
                              rowClass={"justify-content-center g-3"}
                              title={<FormattedMessage id={"common.video-carousel"}/>}>
        <YouTubeVideosCarousel
            items={blok.Items.map(item =>
                ({
                    youtubeVideoId: item.YoutubeVideoId,
                    thumbnailYoutubeVideoId: item.YoutubeCustomThumbnailUrl,
                    title: item.Title,
                })
            )}
        />
    </FullWidthSection1>
}

export default VideoSwiper
import {useLocation, useParams} from "react-router-dom";
import {getAppConfig} from "@commons/infra/appconfig";

/**
 * @returns {string} original route template, e.g. "/doctors/:doctorId/appointments/:appointmentId"
 */
export let useCurrentRouteTemplate = () => {
    let pathname = removeLanguagePrefix(useLocation().pathname)
    let params = useParams()

    if (!Object.keys(params).length) {
        return pathname; // we don't need to replace anything
    }

    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramName === "*")
            return
        if (paramValue) {
            path = path.replace(`/${paramValue}`, `/:${paramName}`);
        }
    });

    return path;
};


export let removeLanguagePrefix = (pathname) => {
    let urlContainsLanguage = !!getAppConfig().i18n.languages.find(l => pathname.startsWith(`/${l}/`))
    let result = urlContainsLanguage ? pathname.substring(3) : pathname;
    return result;
}
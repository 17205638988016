export let createJoinCallOnMobileLink = (roomUrl, token) => {
    return `medon://home/call?url=${btoa(roomUrl + '?t=' + token)}`
}

export let mobileApplicationCallButtonFeature = () => {
    return {
        isEnabled: () => {
            return (window.CONFIG.FEATURES.hasOwnProperty("MOBILE_START_CALL")
                && window.CONFIG.FEATURES.MOBILE_START_CALL === true
            )
        }
    }
}

import React from "react";
import Loading from "@commons/ui/component/Loading";
import './LoadingOverlay.scss'

export let LoadingOverlay = () => {
    return <div className="loading-overlay position-absolute top-0 w-100 h-100 bg-white" style={{zIndex: 9999}}>
        <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
                <Loading/>
            </div>
        </div>
    </div>
}
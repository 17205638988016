import {CenteredHeadingRow1} from "@commons/ui/component/Section";
import {H2} from "@commons/uinew/text/headings";
import React from "react";

export let CenteredH2Section = ({children}) => {
    return <CenteredHeadingRow1>
        <H2 centered={true} bottomPadding={"md"}>
            {children}
        </H2>
    </CenteredHeadingRow1>
}

export let TextSection = ({children}) => {
    return <div className={`offset-lg-2 col-lg-8 offset-md-2 col-md-8`}>
        <div className={`mb-4 fs-lg text-lg-center`}>
            {children}
        </div>
    </div>
}
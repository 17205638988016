import axios from "../../infra/http/axios/axios";

export const PatientProfileApi = {
    getProfile: () => axios.get('/api/patient/profile'),

    getSoProfile: () => axios.get('/api/so/patient/profile'),

    getOngoingReservations: () => axios.get('/api/my/ongoing-reservations'),

    getNotificationSettings: () => axios.get("/api/patient/notification-settings"),

    updateNotificationSettings: ({
                                     isMarketingAllowed,
                                     communicationTypes
                                 }) => axios.post("/api/patient/notification-settings", {
        isMarketingAllowed,
        communicationTypes
    })
};

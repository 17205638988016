import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {defaultAppConfig} from "./config";

let root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <App config={defaultAppConfig}/>
);

if (window.CONFIG.GOOGLE_TAG_MANAGER.ENABLED) {
    import('react-gtm-module').then(tagManager => {
        tagManager.initialize({
            gtmId: window.CONFIG.GOOGLE_TAG_MANAGER.GTM_ID,
        })
        tagManager.initialize({
            gtmId: window.CONFIG.GOOGLE_TAG_MANAGER.SERVER_SIDE_GTM_ID,
        })
    })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import {TickedListItem, TickedListItemV2, TickedListItemWithoutTitle} from "@commons/ui/component/UnorderedList";
import React from "react";
import YouTubeVideo from "@commons/ui/component/YouTubeVideo";
import {
    MARK_LINK,
    NODE_HEADING,
    NODE_LI,
    NODE_OL,
    NODE_PARAGRAPH,
    NODE_UL,
    render
} from 'storyblok-rich-text-react-renderer';
import {TARGET_BLANK_PROPS} from "@commons/infra/helpers";

export let RichText = ({content, contentInserter = () => <></>}) => {
    var paragraphsCounter = 0

    return <>{render(content, {
        blokResolvers: {
            ['YoutubeVideo']: (props) => <div className={"mb-4"}>
                <YouTubeVideo
                    youtubeVideoId={props.VideoId}
                    placeholderVideoId={props.VideoId}
                />
            </div>,
            ['TickedListItem']: (props) => <TickedListItem
                title={props.Title}
                compensateInnerParagraph={true} // RichText field wraps things into <p> automatically
                text={<RichText content={props.Text}/>}
            />,
            ['TickedListItemV2']: (props) => <TickedListItemV2
                title={props.Title}
                compensateInnerParagraph={true} // RichText field wraps things into <p> automatically
                text={<RichText content={props.Text}/>}
            />
        },
        markResolvers: {
            [MARK_LINK]: (children, {linktype, href, target, anchor, uuid, custom}) => {
                let isExternal = href.startsWith("https://") && !href.startsWith(window.CONFIG.FRONTEND_BASE_URL);

                return <a href={href} {...isExternal ? TARGET_BLANK_PROPS : {}}>
                    {children}
                </a>
            },
            [NODE_HEADING]: (children, {level}) => <h4 className={"mt-4"}>
                {children}
            </h4>
        },
        nodeResolvers: {
            [NODE_PARAGRAPH]: (children) => {
                paragraphsCounter++;
                return <>
                    <p>{children}</p>
                    {contentInserter(paragraphsCounter)}
                </>
            },
            [NODE_OL]: (children) => <div className={"mb-3"}>{children}</div>,
            [NODE_UL]: (children) => <div className={"mb-3"}>{children}</div>,
            [NODE_LI]: (children) => <TickedListItemWithoutTitle
                className={"mb-0"}
                icon={"ai-arrow-right"}>
                {children}
            </TickedListItemWithoutTitle>,
        }
    })}</>
}
import {FormattedMessage} from "react-intl";
import React from "react";

export let InputErrorDescription = ({show}) => {
    return show && <ErrorDescription>
        <FormattedMessage id={"pages.sirds-mazspeja.application.form.error-1"}/>
    </ErrorDescription>
}

export let ErrorDescription = ({children, fontSize = 'sm'}) => {
    return <label className={`invalid-feedback fs-${fontSize} mt-2 d-block text-danger`}>
        {children}
    </label>
}

export let CaptchaErrorDescription = () => {
    return <ErrorDescription>
        <FormattedMessage id={"common.error.captcha"}/>
    </ErrorDescription>
}
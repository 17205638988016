export let activeHealthIssues = [
    {
        key: 'alergijas-un-nepanesibas'
    },
    {
        key: 'ausis-deguns-un-kakls'
    },
    {
        key: 'autoimunas-saslimsanas'
    },
    {
        key: 'adas-problemas'
    },
    {
        key: 'elposanas-sistema'
    },
    {
        key: 'garigas-veselibas-problemas'
    },
    {
        key: 'gremosanas-sistema'
    },
    {
        key: 'hormoni-endokrina-sistema'
    },
    {
        key: 'infekcijas-slimibas'
    },
    {
        key: 'kardiovaskulara-sistema'
    },
    {
        key: 'muskuloskeletalas-sistema'
    },
    {
        key: 'neirologiskas-slimibas'
    },
    {
        key: 'reproduktivas-saslimsanas'
    },
    {
        key: 'seksuala-veseliba'
    },
    {
        key: 'urologiskas-problemas'
    },
    {
        key: 'vezis'
    },
]

let keyPrefix = "cms.health-issues"

export let healthIssueDescriptionKey = (healthIssueKey) => `${keyPrefix}.${healthIssueKey}.meta.description`
export let healthIssuePageTitleKey = (healthIssueKey) => `${keyPrefix}.${healthIssueKey}.title`
export let healthIssueCardContentKey = (healthIssueKey) => `${keyPrefix}.${healthIssueKey}.card-content`
export let healthIssueContentKey = (healthIssueKey) => `${keyPrefix}.${healthIssueKey}.content`
import {useState} from "react";

let YouTubeVideo = ({placeholderImageUrl, placeholderVideoId, youtubeVideoId, height = 560, width = 315}) => {
    // eslint-disable-next-line
    let [play, setPlay] = useState(false)
    let onButtonClick = e => {
        e.preventDefault()
        setPlay(true)
    }
    let placeholderImage = placeholderVideoId ? `https://img.youtube.com/vi/${placeholderVideoId}/maxresdefault.jpg` : placeholderImageUrl

    return play ? <iframe width={`${width}`} height={`${height}`}
                          src={`https://www.youtube.com/embed/${youtubeVideoId}?modestbranding=1&showinfo=0&autoplay=1`}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen/> :
        <div className={"bg-secondary d-flex align-items-center justify-content-center rounded-3 position-relative"}
             onClick={onButtonClick}
             style={{
                 cursor: 'pointer',
                 width: '100%',
                 height: `${height}px`,
                 background: `url("${placeholderImage}") center repeat-y`,
                 backgroundSize: 'cover'
             }}>
            <a href="/#"
               onClick={onButtonClick}
               className="btn btn-icon btn-xl btn-primary rounded-circle stretched-link">
                <i class="ai-play-filled"></i>
            </a>
        </div>
}


export default YouTubeVideo
import {H2} from "@commons/uinew/text/headings";
import {FormattedMessage} from "react-intl";
import {PublicLinks} from "../../../../../portal-latvia/src/utility/links";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {DifferentBackgroundSection} from "@commons/uinew/section/DifferentBackgroundSection";
import React from "react";
import {DoctorsCarousel} from "@commons/ui/component/DoctorsCarousel";
import {useSpecialityFormatter} from "@commons/infra/helpers";

export let Doctors = ({blok}) => {
    let specialityFormatter = useSpecialityFormatter()

    return <DifferentBackgroundSection
        bg={"secondary"}>
        <div className={" text-md-center"}>
            <H2>
                {blok.Title}
            </H2>
            <div className={`offset-lg-2 col-lg-8 offset-md-2 col-md-8`}>
                <div className={`mb-4 fs-lg`}>
                    {blok.Text}
                </div>
            </div>
        </div>

        <DoctorsCarousel
            doctors={blok.Doctors.map(json => {
                let item = JSON.parse(json)
                return {
                    link: PublicLinks.doctor(item.slug),
                    img: item.imgSrc,
                    name: item.name,
                    speciality: specialityFormatter.formatSpeciality(item.speciality[0])
                }
            })}
        />

        <div className="pt-1 pt-lg-2 text-center">
            <LocalizedLink to={PublicLinks.doctors()} className={"fs-lg fw-500"}>
                <FormattedMessage id={"pages.home.doctors.button-see-all"}/>
                <i className={"ai ai-arrow-right ms-1"}/>
            </LocalizedLink>
        </div>
    </DifferentBackgroundSection>
}

import React from "react";

let FileLink = ({href, children}) => {
    return <a href={href}
              target={"_blank"}
              className={"hide-external-icon"}
              rel="noopener noreferrer">
        <i className={"ai-file me-1"}/> {children}
    </a>
}

export default FileLink

import {useState} from "react";

export let H2 = ({bottomPadding = 'none', children, centered = false}) => {
    let resolvePaddingClass = (paddingType) => {
        switch (paddingType) {
            case "md":
                return "mb-4 mb-md-4";
            case "lg":
                return "mb-4 mb-md-5";
            case "xl":
                return "mb-6 mb-md-6";
            case "none":
            default:
                return "";
        }
    }
    const [paddingClass, _] = useState(resolvePaddingClass(bottomPadding));

    return <h2 className={`${paddingClass} ${centered ? 'text-center' : ''}`}>
        {children}
    </h2>
}
import React from 'react';
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {LANGUAGE_CODE_LATVIAN} from "@commons/infra/i18n/LocalizedRouter";
import {useLocalizedRoutes} from "@commons/infra/i18n/routes";
import {queryParamsToString} from "@commons/infra/helpers";

let LocalizedLink = ({skipLocalization, ...props}) => {
    let {locale} = useIntl()
    let {unpack} = useLocalizedRoutes()

    let newTo = skipLocalization || locale === LANGUAGE_CODE_LATVIAN ? unpack(props.to) : maybeAddLanguageCode(unpack(props.to), locale);
    if (props.queryParams) {
        newTo = newTo + '?' + queryParamsToString(props.queryParams);
    }
    let newProps = {...props, to: newTo}

    return <Link {...newProps} />
}

let maybeAddLanguageCode = (path, locale) => {
    let isFallbackRoute = path === '*';
    return isFallbackRoute ? path : `/${locale}${path}`;
}

export default LocalizedLink
import {useRedirectPath} from "@commons/ui/component/PostLoginRedirectHandler";
import {Navigate} from "react-router-dom";
import {CommonPatientLinks} from "@commons/infra/links";

export let SuccessfulOAuthRedirectHandler = () => {
    let rp = useRedirectPath()

    return <>
        <Navigate to={rp.getAndForget() || CommonPatientLinks.dashboard()}/>
    </>
}
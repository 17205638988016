import React from 'react';
import {toDateTime} from "@commons/infra/helpers";
import {connect} from "react-redux";
import {clearOngoingReservations} from "@commons/domain/redux/actions";

let Countdown = React.lazy(() => import("react-countdown"));

const ReservationCountdown = ({reservedTill, clearOngoingReservations}) => {
    let dt = toDateTime(reservedTill)
    // let dt = Date.now() + 5000
    return <Countdown
        date={dt.toMillis()}
        // date={dt}
        renderer={props => {
            if (!props.completed) {
                return <span>{props.formatted.minutes}:{props.formatted.seconds}</span>
            } else {
                clearOngoingReservations();
                return <></>
            }
        }}
    />
}

export default connect(null, {clearOngoingReservations})(ReservationCountdown)

import axios from "../../infra/http/axios/axios";

export const ProfileApi = {
    getProfile: () => {
        let url = new URL("/api/doctor/profile", axios.defaults.baseURL);
        return axios.get(url.href)
    },
};

export let hasFinishedRegistration = (profile) => {
    return profile.registrationProgress && profile.registrationProgress.hasFinishedRegistration;
}

export let resolveLinkToNextRegistrationStep = (profile) => {
    if (!profile.speciality || profile.speciality.length === 0) {
        return '/doctor/registration/experience-and-education';
    } else if (!profile.photo) {
        return '/doctor/registration/photo-upload';
    } else {
        return '#';
    }
}

import {useIntl} from "react-intl";

export let Toast = ({icon = 'ai-lock-open', title = 'Secondary toast', type = 'info', text}) => {
    return <div className={"toast show border-0 mb-2"}>
        <div
            className={`${type === 'info' ? 'bg-faded-success' : ''} ${type === 'success' ? 'bg-faded-success' : ''} ${type === 'danger' ? 'text-dark bg-faded-danger' : ''}`}
            style={{borderRadius: 'inherit'}}>

            <div className={`toast-body fs-6 fw-medium d-flex align-items-center gap-1 `}>
                <i className={`${icon} fs-lg me-2`}/>
                {text}
            </div>
        </div>
    </div>
}

export let SuccessToast = ({title, text}) => {
    let {formatMessage} = useIntl();
    if (!title) {
        title = formatMessage({id: "toast.success"})
    }
    return <Toast type={"success"} icon={'ai-check'} title={title} text={text}/>
}

export let InfoToast = ({title, text}) => {
    let {formatMessage} = useIntl();
    if (!title) {
        title = formatMessage({id: "toast.info"})
    }
    return <Toast icon={'ai-check'} title={title} text={text}/>
}

export let ErrorToast = ({title, text}) => {
    let {formatMessage} = useIntl();

    if (!text) {
        text = formatMessage({id: "toast.error.text"})
    }
    if (!title) {
        title = formatMessage({id: "toast.error"})
    }
    return <Toast type='danger' icon={'ai-bell'} title={title} text={text}/>
}


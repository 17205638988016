import React from 'react';
import {Link} from 'react-router-dom';
import {useIntl} from "react-intl";
import {translateLanguage} from "../helpers";
import {useLocalizedRoutes} from "@commons/infra/i18n/routes";


export let LanguageSwitcher = () => {
    let {locale} = useIntl()

    return <ul className="navbar-nav me-3 d-none d-lg-block">
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle fs-sm" role="button" href="/#" onClick={e => {
                e.preventDefault()
            }}>
                <i className={"ai ai-globe me-1"}/>
                {translateLanguage(locale.toUpperCase())}
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
                <LanguageList linkClassName={"dropdown-item"}/>
            </ul>
        </li>
    </ul>
};

export let useMobileLanguages = () => {
    let {locale} = useIntl()
    let lr = useLocalizedRoutes()

    return {
        getMobileLanguages: () => ['lv', 'ru', 'en'] // TODO extract
            .filter(l => l !== locale)
            .map((targetLanguage) => ({
                    text: translateLanguage(targetLanguage),
                    to: lr.getCurrentUrl(targetLanguage),
                    skipLocalization: true
                })
            )
    }
}

export let LanguageList = ({className = '', linkClassName = ''}) => {
    let {locale} = useIntl()
    let lr = useLocalizedRoutes()

    return ['lv', 'ru', 'en'].filter(l => l !== locale).map((targetLanguage) => (
        <li className={"nav-item dropdown " + className}>
            <Link className={linkClassName} to={lr.getCurrentUrl(targetLanguage)} rel="alternate"
                  hreflang={targetLanguage}>
                {translateLanguage(targetLanguage)}
            </Link>
        </li>
    ))
};
import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {connect} from "react-redux";

let FacebookPixel = () => {
    useEffect(() => {
        if (!window.CONFIG.FACEBOOK_PIXEL.ENABLED)
            return

        // Setup
        if (!window.fbq || typeof window.fbq.getState !== 'function') {
            return
        }

        if (window.fbq.getState().pixels.length === 0) {
            window.fbq('consent', 'revoke');
            window.fbq('init', window.CONFIG.FACEBOOK_PIXEL.ID);
        }
    }, [window.fbq])

    let location = useLocation()

    useEffect(() => {
        if (window.fbq) {
            window.fbq('track', 'PageView');
        }
    }, [location.pathname])

    return <></>;
}

let mapStateToProps = (state) => {
    return {
        cookieConsent: state.cookieConsent
    }
};

export default connect(mapStateToProps)(FacebookPixel);

import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import React from "react";

export let ContentColumnsWrapper = ({children, colsSm = 4}) => {
    return <div className="row py-2 py-sm-3 my-md-3">
        <div className="col-lg-12">
            <div className={`row row-cols-1 row-cols-sm-${colsSm} gy-4 gy-lg-5`}>
                {children}
            </div>
        </div>
    </div>
}

export let ContentColumn = ({aiIcon, heading, text, linkTo, linkText}) => {
    return <div className="col aos-init aos-animate" data-aos="fade-up" data-aos-delay="700"
                data-disable-parallax-down="md">
        <div className="pe-sm-2 pe-md-4 me-xl-3">
            <Icon icon={`ai-${aiIcon}`}/>
            <h3 className="h5 fw-bold mb-2">
                {heading}
            </h3>
            <p className="fs-lg mb-2">
                {text}
            </p>
            {linkTo && <LocalizedLink to={linkTo} className={"btn btn-link ps-0"}>
                {linkText} <i className={"ai ai-arrow-right ms-2"}/>
            </LocalizedLink>}
        </div>
    </div>
}

let Icon = ({icon}) => {
    return <div className="d-inline-block text-light rounded mb-3 mb-md-4">
        <i className={`${icon} fs-1 text-body`}/>
    </div>
}
import {LatvianStatisticsSection} from "@commons/uinew/domainsection/prefilled/LatvianStatisticsSection";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";
import {SectionWrapper} from "@commons/infra/storyblok/components/SectionWrapper";

export let LatvianStatisticsSectionStoryblokWrapper = ({blok}) => {
    return <SectionWrapper differentColor={blok.IsDifferentColorSection}>
        <LatvianStatisticsSection
            buttonText={blok.ButtonText}
            buttonLink={parseI18nLink(blok.ButtonLink.url)}
        />
    </SectionWrapper>
}
import {BOOKING_STATUS_OCCUPIED, SPECIALITY_ANESTHESIOLOGIST} from "@commons/infra/consts";
import {FormattedMessage} from "react-intl";
import React from "react";
import FileLink from "@commons/ui/component/FileLink";
import {CommonPatientLinks} from "@commons/infra/links";
import {DoctorLinks} from "../../../../../../../portal-latvia/src/utility/links";
import ValueOrPlaceholder from "@commons/ui/component/ValueOrPlaceholder";

export let FORM_GENERIC = 'FORM_GENERIC';
export let FORM_ANESTHESIOLOGIST = 'FORM_ANESTHESIOLOGIST';

export let resolvePatientInfoForm = (doctorSpecialities) => {
    if (doctorSpecialities.includes(SPECIALITY_ANESTHESIOLOGIST)) {
        return FORM_ANESTHESIOLOGIST
    } else {
        return FORM_GENERIC
    }
}

export let canEditPatientInfo = (bookingStatus) => bookingStatus === BOOKING_STATUS_OCCUPIED

export let EditButton = ({bookingStatus, onClick}) => {
    return <>
        {canEditPatientInfo(bookingStatus) &&
            <button className={"btn btn-secondary"}
                    onClick={onClick}>
                <i className={"ai ai-edit me-2"}/>
                <FormattedMessage id={'pages.patient-booking.edit-button'}/>
            </button>}
    </>
}

export let DocumentsView = ({documents, bookingId}) => {
    return <>
        {documents.map(document => {
            return <li className={"list-unstyled"}>
                <FileLink
                    href={CommonPatientLinks.document.view(document.id)}>
                    {document.name}
                </FileLink>
            </li>
        })}
    </>
}

export let DoctorDocumentsView = ({documents, bookingId}) => {
    return <ValueOrPlaceholder
        isSet={documents.length > 0}
        value={<ul className={"list-unstyled"}>
            {documents.map(document =>
                <li>
                    <FileLink
                        href={DoctorLinks.booking.document(bookingId, document.id)}>
                        {document.name}
                    </FileLink>
                    &nbsp;
                    <a className={"text-muted fs-sm"}
                       href={DoctorLinks.booking.downloadDocument(bookingId, document.id)}>
                        lejupielādēt
                    </a>
                </li>)}
        </ul>}/>
}

import FaqAccordion from "@commons/ui/component/FaqAccordion";
import React from "react";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {H2} from "@commons/uinew/text/headings";

export let FaqSectionWithArrow = ({title, text, buttonText, showFaqLink, faqLink, faqItems}) => {
    return <div className="row">
        <div className="col-md-4 text-center text-md-start">
            <H2>{title}</H2>
            <p className="pb-3 pb-sm-4">
                {text}
            </p>
        </div>
        <div className="col-md-8 col-lg-7 offset-lg-1">
            <div className="accordion" id="faq">
                <FaqAccordion items={faqItems}/>
            </div>
            {showFaqLink && <LocalizedLink to={faqLink} className={"btn btn-link mt-3"}>
                {buttonText} <i class={"ai-arrow-right ms-2"}/>
            </LocalizedLink>}
        </div>
    </div>
}
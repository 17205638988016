import {Route} from "react-router-dom";
import PublicHeader from "../../common/component/PublicHeader";
import {FullHeightWrapper} from "@commons/uinew/layout/FullHeightWrapper";
import DoctorFooter from "../../doctor/component/DoctorFooter";
import React from "react";
import PlayPage from "./page/PlayPage";
import {PatientRestricted} from "../../App";
import {QaGameLinks} from "./QaGameLinks";
import ResultsPage from "./page/ResultsPage";

export let useQaGameRoutes = () => {
    return <>
        <Route path={`/qa-game/results`} element={<PatientRestricted>
            <PublicHeader/>
            <FullHeightWrapper bg={"secondary"}>
                <ResultsPage/>
            </FullHeightWrapper>
            <DoctorFooter variant={'dark'}/>
        </PatientRestricted>}/>

        <Route path={QaGameLinks.play()} element={<PatientRestricted>
            <PublicHeader/>
            <FullHeightWrapper bg={"secondary"}>
                <PlayPage/>
            </FullHeightWrapper>
            <DoctorFooter variant={'dark'}/>
        </PatientRestricted>}/>
    </>
}
import {BenefitSectionV1} from "@commons/uinew/domainsection/benefits/BenefitsSectionV1";
import {DifferentBackgroundSection} from "@commons/uinew/section/DifferentBackgroundSection";
import {RichText} from "@commons/infra/storyblok/richText";

export let Benefits = ({blok}) => {
    return <DifferentBackgroundSection bg={blok.BackgroundClass.replace("bg-", "")}>
        <BenefitSectionV1
            title={blok.Title}
            items={blok.Items.map(i => ({
                aiIcon: i.aiIcon,
                heading: i.Title,
                text: <RichText content={i.Text}/>,
            }))}
        />
    </DifferentBackgroundSection>
}
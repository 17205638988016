import React from "react";
import {DoctorsCarouselWrapper} from "@commons/ui/component/DoctorsCarouselWrapper";

let SpecialityDoctorSwiper = ({blok}) => {
    return <DoctorsCarouselWrapper
        className={blok.BackgroundClass}
        title={blok.Title}
        specialityCodes={blok.Specialities}
    />
}

export default SpecialityDoctorSwiper
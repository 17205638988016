import {FormattedMessage} from "react-intl";
import React from "react";
import {TickedListItem, TickedListItemWithoutTitle} from "@commons/ui/component/UnorderedList";

export let CustomFormattedMessage = ({id, values}) => {
    let values2 = {
        ...{
            br: <br/>,
            span: chunks => <span>{chunks}</span>,
            p: chunks => <p>{chunks}</p>,
            strong: chunks => <strong>{chunks}</strong>,
            ul: chunk => <ul className={"mt-2"}>{chunk}</ul>,
            li: chunk => <li>{chunk}</li>,
            item: chunks => <TickedListItem icon="ai-arrow-right" text={chunks}/>,
            checkItem: chunks => <TickedListItem icon="ai-check" text={chunks}/>,
            checkItemWithoutTitle: chunks => <TickedListItemWithoutTitle
                icon="ai-check">{chunks}</TickedListItemWithoutTitle>,
        }, ...values
    };
    return <FormattedMessage
        id={id}
        values={values2}/>
}

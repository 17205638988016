import {HeroWithVideoSection as OriginalHeroWithVideoSection} from "@commons/uinew/component/heading";
import React from "react";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";

let HeroWithVideoSection = ({blok}) => {
    return <OriginalHeroWithVideoSection
        backgroundClass={blok.BackgroundClass}
        videoPoster={"/img/home-v2/video-placeholder.jpeg"}
        videoUrl={"https://d.medon.lv/public/home.mp4"}
        title={blok.Title}
        text={blok.Text}
        buttonText={blok.ButtonText}
        buttonLink={parseI18nLink(blok.ButtonLink.url)}
        secondaryButtonText={blok.SecondaryButtonText}
        secondaryButtonLink={blok.SecondaryButtonLink ? parseI18nLink(blok.SecondaryButtonLink.url) : null}
    />
}

export default HeroWithVideoSection

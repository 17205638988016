import {useQuery} from "@commons/infra/helpers";
import {useEffect} from "react";

export let UTM_PROPERTIES = {}

export let UtmPropertiesCapturer = () => {
    let query = useQuery()

    useEffect(() => {
        (['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']).forEach(fieldName => {
            UTM_PROPERTIES[fieldName] = query.get(fieldName)
        })
    }, []);

    return <></>
}
import {FormattedMessage, useIntl} from "react-intl";
import React from "react";

export let HelloText = ({name}) => {
    let {formatMessage} = useIntl()

    return <span className={"sensitive-data"}>
        {name && <FormattedMessage id={"pages.dashboard.hello"} values={{
            name: <span className={"sensitive-data hw-not-allow"}>{name}</span>
        }}/>}
        {!name && formatMessage({id: "pages.dashboard.hello"}, {name: ""}).replace(",", "!")}
    </span>
}
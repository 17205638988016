import {SectionWrapper} from "@commons/infra/storyblok/components/SectionWrapper";
import {
    LatvianPublicationsSwiperSection
} from "@commons/uinew/domainsection/prefilled/LatvianPublicationsSwiperSection";
import {CenteredH2Section} from "@commons/uinew/domaincomponent/text";
import React from "react";

export let LatvianPublicationsSwiperSectionStoryblokWrapper = ({blok}) => {
    return <SectionWrapper differentColor={blok.IsDifferentColorSection}>
        <CenteredH2Section>
            {blok.Title}
        </CenteredH2Section>

        <LatvianPublicationsSwiperSection/>
    </SectionWrapper>
}
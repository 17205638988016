import {StatisticsSectionV1} from "@commons/uinew/domaincomponent/statistics";
import React from "react";

export let LatvianStatisticsSection = ({buttonText, buttonLink}) => {
    return <StatisticsSectionV1
        text={"Tūkstošiem apmierinātu klientu, kas iesaka Medon attālinātās konsultācijas arī citiem"}
        ctaText={buttonText}
        ctaLink={buttonLink}
        items={[{
            title: "92%+", text: "iesaka citiem"
        }, {
            title: "3 dienas", text: "līdz vizītei (vidēji)"
        }, {
            title: "128", text: "ārsti reģistrējušies"
        }, {
            title: "25", text: "specialitātes"
        },]}
    />
}

import CtaSectionWithArrow from "@commons/uinew/domainsection/cta/CtaSectionWithArrow";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";

export let CtaWithArrow = ({blok}) => {
    return <CtaSectionWithArrow
        title={blok.Title}
        text={blok.Text}
        buttonLink={parseI18nLink(blok.ButtonLink)}
        buttonText={blok.ButtonText}
        className={blok.BackgroundClass}
    />
}
import React, {Suspense} from "react";
import LightGallery from "lightgallery/react";
import lgVideo from "lightgallery/plugins/video";
import {BackgroundVideo} from "@commons/infra/video";
import LazySwiper from "@commons/uinew/component/swiper/LazySwiper";
import {DEFAULT_BREAKPOINTS_SM_2, defaultSwiperSettings} from "@commons/uinew/component/swiper";

export let hasYoutubeVideoCarousel = (db, slug) => db && db.hasOwnProperty(slug)

export let YoutubeVideosCarousel = ({db, slug, lgItems = 3.2}) => {
    if (!hasYoutubeVideoCarousel(db, slug)) {
        return <></>
    }

    return <YouTubeVideosCarousel items={db[slug].videos} lgItems={lgItems}/>
}

export let YouTubeVideosCarousel = ({items, lgItems = 3.2}) => {
    return <Suspense fallback={<div>Loading...</div>}>
        <LazySwiper
            {...defaultSwiperSettings()}
            autoHeight={false}
            breakpoints={DEFAULT_BREAKPOINTS_SM_2}
            slideClassName={"align-items-start pb-lg-3 pb-md-2"}
            items={items.map(i => <YouTubeVideoCard
                youtubeVideoId={i.youtubeVideoId}
                thumbnailYoutubeVideoId={i.thumbnailYoutubeVideoId}
                title={i.title}
                text={i.text}
                previewVideoUrl={i.previewVideoUrl}
            />)}/>
    </Suspense>
}


export let YouTubeVideoCard = ({
                                   title, text, previewVideoUrl, youtubeVideoId, thumbnailYoutubeVideoId
                               }) => {
    return <LightGallery
        plugins={[lgVideo]}
        videojs={false}
        enableDrag={false}
        controls={false}
        counter={false}
        download={false}
        escKey={true}
        autoplayFirstVideo={true}
        forceSlideShowAutoplay={true}
        youTubePlayerParams={{
            modestbranding: 1, showinfo: 0, ref: 0, mute: 0, autoplay: 0
        }}>
        <a
            href={`https://www.youtube.com/watch?v=${youtubeVideoId}`}
            data-lg-size="1280-720"
            data-src={`https://www.youtube.com/watch?v=${youtubeVideoId}`}
            className="card card-lifted border-0 rounded-5 overflow-hidden text-start pointer-if-enabled"
        >
            {!previewVideoUrl && <>
                <img
                    src={`https://img.youtube.com/vi/${thumbnailYoutubeVideoId || youtubeVideoId}/maxresdefault.jpg`}
                    alt={"Video placeholder"}
                    loading={"lazy"}
                    style={{
                        objectFit: 'cover',
                        height: '460px',
                    }}/>
                <span class="position-absolute top-0 start-0 w-100 h-100 z-1 "
                      style={{background: 'linear-gradient(180deg, rgba(18, 21, 25, 0.00) 35.56%, #121519 95.3%)'}}></span>
                <div class="position-absolute bottom-0 w-100 z-2 p-4">
                    <div>
                        <div class="d-flex align-items-center justify-content-between">
                            <div
                                className="btn btn-icon btn-sm bg-white text-dark rounded-circle stretched-link ms-0 me-3 d-none d-lg-block align-content-center">
                                <i class="ai-play-filled"/>
                            </div>
                            <div>
                                {text && <h3 class="h6 fw-normal text-white mb-0">{text}</h3>}
                                {title && <h6 className={"text-white fs-sm mt-2 mb-0"}>{title}</h6>}
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            {previewVideoUrl && <>
                <span class="position-absolute top-0 start-0 w-100 h-100"
                      style={{
                          background: 'linear-gradient(180deg, rgba(18, 21, 25, 0.00) 35.56%, #121519 95.3%)',
                      }}>
                </span>
                <div class="position-absolute bottom-0 w-100 p-4" style={{zIndex: 9999}}>
                    <div class="d-flex align-items-center justify-content-between">
                        <div
                            className="btn btn-icon btn-sm bg-white text-dark rounded-circle stretched-link ms-0 me-3 d-none d-lg-block align-content-center">
                            <i class="ai-play-filled"/>
                        </div>
                        <div>
                            {text && <h3 class="h6 fw-normal text-white mb-0">{text}</h3>}
                            {title && <h6 className={"text-white fs-sm mt-2 mb-0"}>{title}</h6>}
                        </div>
                    </div>
                </div>
                <div>
                    <BackgroundVideo
                        style={{
                            backgroundImage: `url(https://img.youtube.com/vi/${thumbnailYoutubeVideoId || youtubeVideoId}/maxresdefault.jpg)`,
                            backgroundPosition: 'top center',
                            minHeight: '400px',
                            backgroundSize: '1000px',
                            position: "relative"
                        }}

                        // Without "position" and "z-index" video get on top of overlay on swipe
                        className="w-100 position-relative z-n1 bg-secondary"
                        // poster={`https://img.youtube.com/vi/${thumbnailYoutubeVideoId || youtubeVideoId}/maxresdefault.jpg`}
                        src={`${previewVideoUrl}`}
                    />
                </div>
            </>}
        </a>
    </LightGallery>

}

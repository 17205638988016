import React from "react";
import {logout} from "@commons/domain/redux/actions";
import {connect} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";
import Loading from "./Loading";

const Restricted = ({authentication, content, authority, otherwiseRedirectTo}) => {
    let location = useLocation()

    if (authentication.isUpdating) {
        return <div className={"text-center mt-5"}>
            <Loading/>
        </div>
    }

    let redirectTo = maybeAddRedirectPathParameter(location, otherwiseRedirectTo)

    if (!authentication.isAuthenticated || !authentication.authorities.includes(authority)) {
        return <Navigate replace to={redirectTo}/>;
    }

    return content;
};

export let JUST_LOGGED_OUT_HASH = 'logout'

let maybeAddRedirectPathParameter = (location, otherwiseRedirectTo) => {
    if (location.hash === `#${JUST_LOGGED_OUT_HASH}`) {
        return otherwiseRedirectTo
    } else {
        let returnPath = location.pathname + location.search + location.hash;
        return otherwiseRedirectTo + '?rp=' + encodeURIComponent(returnPath)
    }
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication
    }
};

const mapDispatchToProps = {logout};

export default connect(mapStateToProps, mapDispatchToProps)(Restricted)

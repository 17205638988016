import React, {lazy,} from 'react';
import {BrowserRouter, Navigate, Route, Routes, useParams} from "react-router-dom";
import {Container} from "react-bootstrap";
import {applyMiddleware, compose, createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {Provider} from "react-redux";
import PublicFooter from "./common/component/PublicFooter";
import {AUTHORITY_DOCTOR, AUTHORITY_PARTNER, AUTHORITY_PATIENT, BOOKING_STATUS_OCCUPIED} from "@commons/infra/consts";
import {Slide, ToastContainer} from 'react-toastify';

import '@around/scss/main.scss'
import {Helmet} from "react-helmet";
import {CustomRedirectSaver} from "@commons/ui/component/PostLoginRedirectHandler";
import FacebookPixel from "@commons/infra/component/FacebookPixel";
import FullscreenLayout from "./doctor/FullscreenLayout";
import PatientLinks, {PublicLinks} from "./utility/links";
import {pages} from "./partner/common/utils/PartnerUtlis";
import rootSaga from "@commons/domain/redux/sagas";
import reducers from "@commons/domain/redux/reducers";
import {LocalizedRouter} from "@commons/infra/i18n/LocalizedRouter";
import AuthenticationChecker from "@commons/infra/AuthenticationChecker";
import {setSharedStore} from "@commons/shared";
import PublicHeader from "./common/component/PublicHeader";
import {ScrollToTop} from "@commons/infra/ScrollToTop";
import DoctorListCta from "@commons/ui/component/DoctorListCta";
import {DoctorApi} from "@commons/api/public/DoctorApi";
import {AppointmentsApi} from "@commons/api/patient/AppointmentApi";
import {PatientAppointmentsApi} from "@commons/api/patient/PatientAppointmentApi";
import {activeHealthIssues} from "./public/page/healthissues/health-issues";
import {CtaSectionWithArrow} from "@commons/ui/component/Section";
import {FormattedMessage} from "react-intl";
import {FORM_ANESTHESIOLOGIST} from "@commons/ui/page/patient/booking/patientinfo/patientInfoForm";
import {CommonPatientLinks, CommonPublicLinks} from "@commons/infra/links";
import {faqItems} from "./public/page/faq";
import {PosthogPageviewTracker} from "@commons/infra/thirdparty/posthog";
import {VIDEOS} from "./db/videos";

/**
 * Workaround to preload language and bypass eslint and IDEA warnings
 */
import Latvian from '@commons/infra/i18n/translations/lv.json';
import {useLocalizedRoutes} from "@commons/infra/i18n/routes";
import {setAppConfig} from "@commons/infra/appconfig";
import {defaultAppConfig} from "./config";
import {ParallaxJs} from "@commons/ui/parallax";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {apiPlugin, RichTextSchema, storyblokInit} from "@storyblok/react";
import storyblokComponentsMapping from "@commons/infra/storyblok/components/_mapping";
import {LoadingOverlay} from "@commons/uinew/component/loading/LoadingOverlay";
import {usePatientRegistrationRoutes} from "./patient/page/onboarding/routes";
import {PatientOnboardingLinks} from "./patient/page/onboarding/links";
import {LatvianMetaLinksHelmet} from "@commons/infra/i18n/MetaLinksHelmet";
import {useQaGameRoutes} from "./patient/qagame/routes";
import {SuccessfulOAuthRedirectHandler} from "@commons/ui/page/public/SuccessfulOAuthRedirectHandler";
import {FullHeightInner, FullHeightWrapper} from "@commons/uinew/layout/FullHeightWrapper";
import {MinimalisticFooter} from "@commons/uinew/domainsection/footer/MinimalisticFooter";
import {ErrorBoundary, Provider as RollbarProvider} from '@rollbar/react';
import PosthogSurveyTrigger from "@commons/infra/posthog/SurveyTrigger";
import {UtmPropertiesCapturer} from "@commons/infra/UtmPropertiesCapturer";

// import {anesthesiologistPatientInfoFormFactory} from "./common/anesthesiologist/anesthesiologistPatientInfoFormFactory";
// eslint-disable-next-line
Latvian.length

// Lazy load public routes
let LoginPage = lazy(() => import("@commons/uinew/page/login/LoginPage"))
let AboutPage = lazy(() => import("./public/page/AboutPage"))
let HomePageV2 = lazy(() => import("./public/page/HomePageV2"))
let HowItWorksPage = lazy(() => import("./public/page/HowItWorksPage"))
let ContactsPage = lazy(() => import("./public/page/ContactsPage"))
let DoctorRegistrationStep1Page = lazy(() => import("./public/page/doctorregistration/DoctorRegistrationStep1Page"))
let PatientRegistrationStep2Page = lazy(() => import("./public/page/patientregistration/PatientRegistrationStep2Page"))
let SingleDoctorPage = lazy(() => import("@commons/ui/page/public/SingleDoctorPage"))
let FaqPage = lazy(() => import("./public/page/FaqPage"))
let RequestPasswordRecoveryPage = lazy(() => import("@commons/uinew/page/passwordrecovery/RequestPasswordRecoveryPage"))
let PasswordRecoveryPasswordChangePage = lazy(() => import("@commons/uinew/page/passwordrecovery/PasswordRecoveryPasswordChangePage"))
let HowItWorksForDoctorPage = lazy(() => import("./public/page/HowItWorksForDoctorPage"))
let ParSirdiPage = lazy(() => import("./public/page/promotion/sirdsveseliba/ParSirdiPage"))
let CardioTestPromotionPage = lazy(() => import("./public/page/CardioTestPromotionPage"))
let DoctorRegistrationPage = lazy(() => import("./public/page/DoctorRegistrationPage"))
let NotFoundPage = lazy(() => import("@commons/ui/page/public/NotFoundPage"))
let ThankYouEmailConfirmed = lazy(() => import("./public/page/thankyou/ThankYouEmailConfirmed"))
let ThankYouUnsubscribed = lazy(() => import("./public/page/thankyou/ThankYouUnsubscribed"))
let HealthIssuesPage = lazy(() => import("@commons/ui/page/public/CardsPagePartial"))
let HealthIssueLocalizedPage = lazy(() => import("@commons/ui/page/public/HealthIssuePage").then(module => ({default: module.HealthIssueLocalizedPage})))
let CameraMicAccessInstructions = lazy(() => import("@commons/ui/page/public/CameraMicHowTo"))
let Restricted = lazy(() => import("@commons/ui/component/Restricted"))
let AnesthesiologistDischargePage = lazy(() => import("@anesthesiology/doctor/discharge/DischargePage"))
let HowItWorksForDoctorVideoPage = lazy(() => import("./public/page/HowItWorksForDoctorVideoPage"))
let ForeignPatientsLanding = lazy(() => import("./public/page/ForeignPatientsLanding"))
let HealthIssueStoryblokPage = lazy(() => import("@commons/ui/page/public/storyblok/HealthIssueStoryblokPage"))
let StoryblokLandingPage = lazy(() => import("@commons/ui/page/public/storyblok/StoryblokLandingPage"))
let StoryblokSpecialityPage = lazy(() => import("@commons/ui/page/public/storyblok/StoryblokSpecialityPage"))

// Sieviešu sirds veselība promo
let SieviesuSirdsVeselibaPage = lazy(() => import("./public/page/promotion/sieviesusirdsveseliba/SieviesuSirdsVeselibaPage"))
let SieviesuSirdsVeselibaApplicationPage = lazy(() => import("./public/page/promotion/sieviesusirdsveseliba/ApplicationPage"))
let SieviesuSirdsVeselibaApplicationAlreadyExistsPage = lazy(() => import("./public/page/promotion/sieviesusirdsveseliba/ApplicationAlreadyExistsPage"))
let SieviesuSirdsVeselibaApplicationSuccessPage = lazy(() => import("./public/page/promotion/sieviesusirdsveseliba/ApplicationSuccessPage"))

// Sirds mazspēja promo
let SirdsMazspejaPage = lazy(() => import("./public/page/promotion/sirdsmazspeja/SirdsMazspejaPage"))
let SirdsMazspejaApplicationPage = lazy(() => import("./public/page/promotion/sirdsmazspeja/ApplicationPage"))
let SirdsMazspejaApplicationAlreadyExistsPage = lazy(() => import("./public/page/promotion/sirdsmazspeja/ApplicationAlreadyExistsPage"))
let SirdsMazspejaApplicationSuccessPage = lazy(() => import("./public/page/promotion/sirdsmazspeja/ApplicationSuccessPage"))

// Viegli but
let ViegliButPage = lazy(() => import("./public/page/promotion/vieglibut/ViegliButPage"))
let ViegliButApplicationAlreadyExistsPage = lazy(() => import("./public/page/promotion/vieglibut/ApplicationAlreadyExistsPage"))
let ViegliButApplicationSuccessPage = lazy(() => import("./public/page/promotion/vieglibut/ApplicationSuccessPage"))
let LongenesisApplicationPage = lazy(() => import("./public/page/promotion/vieglibut/LongenesisApplicationPage"));

// Viegli but petijums
let ViegliButPetijumsPage = lazy(() => import("./public/page/promotion/vieglibutpetijums/ViegliButPage"))
let ViegliButPetijumsApplicationPage = lazy(() => import("./public/page/promotion/vieglibutpetijums/ApplicationPage"))
let ViegliButPetijumsApplicationAlreadyExistsPage = lazy(() => import("./public/page/promotion/vieglibutpetijums/ApplicationAlreadyExistsPage"))
let ViegliButPetijumsApplicationSuccessPage = lazy(() => import("./public/page/promotion/vieglibutpetijums/ApplicationSuccessPage"))
let ViegliButPetijumsApplicationFailurePage = lazy(() => import("./public/page/promotion/vieglibutpetijums/ApplicationFailurePage"))

// Internet Aptieka
let InternetAptiekaPromoPage = lazy(() => import("./public/page/promotion/internetaptieka/InternetAptiekaPromoPage"))

// Lazy load doctor routes
let DoctorCallPage = lazy(() => import("./doctor/page/appointment/CallPage"))
let DoctorDashboardPage = lazy(() => import("./doctor/page/dashboard/DoctorDashboardPage"))
let PatientsPage = lazy(() => import("./doctor/page/PatientsPage"))
let PatientPage = lazy(() => import("./doctor/page/PatientPage"))
let DoctorAppointmentsPage = lazy(() => import("./doctor/page/DoctorBookingsPage"))
let DoctorSchedulesPage = lazy(() => import("./doctor/page/DoctorSchedulesPage"))
let CalendarPage = lazy(() => import("./doctor/page/CalendarPage"))
let DoctorProfilePage = lazy(() => import("./doctor/page/settings/DoctorProfilePage"))
let DoctorAppointmentPage = lazy(() => import("./doctor/page/appointment/BookingPage"))
let AppointmentRequestPage = lazy(() => import("./doctor/page/appointment/AppointmentRequestPage"))
let ExperienceAndEducationStepPage = lazy(() => import("./doctor/page/registration/ExperienceAndEducationStepPage"))
let PhotoUploadStepPage = lazy(() => import("./doctor/page/registration/PhotoUploadStepPage"))
let RequestAppointmentPage = lazy(() => import("./public/page/requestappointment/RequestAppointmentPage"))
let RequestAppointmentConfirmation = lazy(() => import("./public/page/requestappointment/RequestAppointmentConfirmationPage"))
let DoctorFooter = lazy(() => import("./doctor/component/DoctorFooter"))
let DefaultDoctorLayout = lazy(() => import("./doctor/DefaultLayout"))

// Lazy load patient routes
let AppointmentReservationPage = lazy(() => import("@commons/ui/page/appointment/AppointmentReservationPage"))
let PatientBookingPaymentPageLvWrapper = lazy(() => import("@commons/ui/page/appointment/PatientBookingPaymentPage").then(module => ({default: module.PatientBookingPaymentPageLvWrapper})))
let PatientBookingPaymentStatusPage = lazy(() => import("@commons/ui/page/appointment/PatientBookingPaymentStatusPage"))
let PatientBookingPatientInfoPage = lazy(() => import("@commons/ui/page/patient/booking/patientinfo/PatientBookingPatientInfoPage"))
let PatientBookingAuthenticationPage = lazy(() => import("@commons/ui/page/appointment/PatientBookingAuthenticationPage"))
let PatientAuthenticationStatusPage = lazy(() => import("@commons/ui/page/appointment/PatientAuthenticationStatusPage"))
let DoctorListPage = lazy(() => import("@commons/ui/page/public/DoctorListPage"))
let PatientAppointmentRequestPage = lazy(() => import("./patient/page/PatientAppointmentRequestPage"))
let PatientDashboardPage = lazy(() => import("./patient/page/PatientDashboardPage"))
let PatientProfilePage = lazy(() => import("@commons/ui/page/patient/PatientProfilePage"))
let PatientCallPage = lazy(() => import("@commons/ui/page/patient/CallPage"))
let AnesthesiologyPatientConsentPage = lazy(() => import("@anesthesiology/patient/consentform/ConsentFormPage"))
let AnesthesiologyPatientConditionPage = lazy(() => import("@anesthesiology/patient/condition/ConditionPage"))
let AnesthesiologyPatientConditionCard = lazy(() => import("@anesthesiology/patient/condition/ConditionCard0").then(module => ({default: module.ConditionCard0})))
let AnesthesiologyPatientConsentCard = lazy(() => import("@anesthesiology/patient/consentform/ConsentCard").then(module => ({default: module.ConsentCard})))

let PatientBookingsPage = lazy(() => import("@commons/ui/page/PatientBookingsPage"))
let PatientBookingPageV2 = lazy(() => import("@commons/ui/page/patient/booking/PatientBookingPageV2"))
let PlatformSelectionPage = lazy(() => import("@commons/ui/page/appointment/CallPlatformSelectionPage"))
let MobileQrCodePage = lazy(() => import("@commons/ui/page/appointment/MobileQrCodePage"))
let LongenesisSurveyPage = lazy(() => import("./patient/page/longenesis/LongenesisSurveyPageV2"))
let LongenesisSurveysPage = lazy(() => import("./patient/page/longenesis/LongenesisSurveysPage"))

// Partner pages
let PartnerLoginPage = lazy(() => import("./partner/common/page/doctor/auth/PartnerLoginPage"))
let PartnerEntryPointPage = lazy(() => import("./partner/users/PartnerEntryPointPage"))

// Storyblok
let HealthIssuesStoryblokPage = lazy(() => import("@commons/ui/page/public/storyblok/HealthIssuesStoryblokPage"));
let StoryblokFakeNavigationPage = lazy(() => import("@commons/ui/page/public/storyblok/StoryblokFakeNavigationPage"));
let SpecialitiesStoryblokPage = lazy(() => import("@commons/ui/page/public/storyblok/SpecialitiesStoryblokPage"));
let BlogStoryblokPage = lazy(() => import("@commons/uinew/page/BlogStoryblokPage"));
let BlogPostStoryblokPage = lazy(() => import("@commons/uinew/page/BlogPostStoryblokPage"));
let SpecificHealthIssueStoryblokPage = lazy(() => import("@commons/ui/page/public/storyblok/SpecificHealthIssueStoryblokPage"));
let RecaptchaAware = lazy(() => import("@commons/infra/thirdparty/recaptcha").then(module => ({default: module.RecaptchaAware})))

// Redux
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);
setSharedStore(store);
sagaMiddleware.run(rootSaga);

// Access rights
export const DoctorRestricted = (props) => {
    return <Restricted authority={AUTHORITY_DOCTOR} otherwiseRedirectTo='/login' content={props.children}/>
};

export const PatientRestricted = (props) => {
    return <Restricted authority={AUTHORITY_PATIENT} otherwiseRedirectTo='/login' content={props.children}/>
};

export const PartnerRestricted = (props) => {
    return <Restricted authority={AUTHORITY_PARTNER} otherwiseRedirectTo='/partners/login' content={props.children}/>
};


storyblokInit({
    accessToken: "49vh73lJ8CTeCDUmAsPovAtt", // TODO extract
    use: [apiPlugin],
    apiOptions: {
        region: "eu",
    },
    components: storyblokComponentsMapping,
    richText: {
        schema: RichTextSchema,
        resolver: (component, blok) => {
            switch (component) {
                case "my-custom-component":
                    return `<div class="my-component-class">${blok.text}</div>`;
                default:
                    return "Resolver not defined";
            }
        },
    },
})

const rollbarConfig = {
    accessToken: window.CONFIG.ROLLBAR.API_KEY,
    // captureUncaught: true,
    // captureUnhandledRejections: true,
    environment: window.CONFIG.ROLLBAR.ENVIRONMENT,
    // server: {
    //     root: "http://example.com/",
    //     branch: "main"
    // },
    // code_version: "0.13.7",
    // payload: {
    //     person: {
    //         id: 117,
    //         email: "chief@unsc.gov",
    //         username: "john-halo"
    //     }
    // }
};


// Misc
export let title = (title) => `${title} | Medon`

setAppConfig(defaultAppConfig)

let App = () => {
    return <Provider store={store}>
        <Helmet>
            <meta content={`${window.CONFIG.BACKEND_BASE_URL}/img/medon-meta.png`} property="twitter:image"/>
            <meta content={`${window.CONFIG.BACKEND_BASE_URL}/img/medon-meta.png`} property="og:image"/>
        </Helmet>
        <AuthenticationChecker/>
        <LocalizedRouter RouterComponent={BrowserRouter}>
            <React.Suspense fallback={<LoadingOverlay/>}>
                <PosthogPageviewTracker/>
                <CustomRedirectSaver/>
                <ScrollToTop/>
                <UtmPropertiesCapturer/>
                {window.CONFIG.FACEBOOK_PIXEL.ENABLED && <FacebookPixel/>}
                <ToastContainer
                    autoClose={5000}
                    toastClassName={() => ""}
                    closeOnClick={true}
                    transition={Slide}
                    position={"top-center"}
                    hideProgressBar={true}
                    limit={1}
                />

                <RollbarProvider config={rollbarConfig}>
                    <ErrorBoundary>
                        <Routes1/>
                    </ErrorBoundary>
                </RollbarProvider>

                <ParallaxJs/>
                <PosthogSurveyTrigger/>
            </React.Suspense>
        </LocalizedRouter>
    </Provider>
}

let Routes1 = () => {
    let {getTemplate} = useLocalizedRoutes()
    let patientRegistrationRoutes = usePatientRegistrationRoutes();
    let qaGameRoutes = useQaGameRoutes()

    return <Routes>
        {patientRegistrationRoutes}
        {qaGameRoutes}

        <Route path="/lv" element={
            <Navigate replace to={'/'}/>
        }/>

        <Route path="/dubai" element={
            <Navigate replace to={'/en/'}/>
        }/>

        <Route path="/oauth-login-handler" element={<>
            <SuccessfulOAuthRedirectHandler/>
        </>}/>

        <Route path="/login" element={<>
            <LatvianMetaLinksHelmet/>
            <PublicHeader/>

            <FullHeightWrapper>
                <LoginPage
                    showRegisterLink={true}
                    registrationLink={PatientOnboardingLinks.step1()}
                />
            </FullHeightWrapper>

            <MinimalisticFooter/>
        </>}/>

        <Route path="/password-recovery/change-password" element={<>
            <LatvianMetaLinksHelmet/>
            <PublicHeader/>

            <FullHeightWrapper>
                <FullHeightInner>
                    <PasswordRecoveryPasswordChangePage/>
                </FullHeightInner>
            </FullHeightWrapper>

            <MinimalisticFooter/>
        </>}/>

        <Route path="/password-recovery" element={<>
            <LatvianMetaLinksHelmet/>
            <PublicHeader/>

            <FullHeightWrapper>
                <FullHeightInner>
                    <RequestPasswordRecoveryPage/>
                </FullHeightInner>
            </FullHeightWrapper>

            <MinimalisticFooter/>
        </>}/>

        {/**
         *
         * Mostly patient pages
         *
         */}

        <Route path="/my/dashboard" element={
            <PatientRestricted>
                <PublicHeader shadow={false} bgClassName={"bg-faded-primary-solid"}/>
                <div className={"main-content bg-faded-primary"}>
                    <PatientDashboardPage/>
                </div>
                <DoctorFooter variant={'dark'}/>
            </PatientRestricted>
        }/>

        <Route path="/my/dashboard-v2" element={
            <PatientRestricted>
                <PublicHeader shadow={false} bgClassName={"bg-faded-primary"}/>
                <div className={"main-content bg-faded-primary"}>
                    <PatientDashboardPage/>
                </div>
                <DoctorFooter variant={'dark'}/>
            </PatientRestricted>
        }/>

        <Route path="/my/profile/*" element={
            <PatientRestricted>
                <PublicHeader shadow={false} bgClassName={"bg-faded-primary-solid"}/>
                <div className={"main-content bg-faded-primary"}>
                    <PatientProfilePage/>
                </div>
                <DoctorFooter variant={'dark'}/>
            </PatientRestricted>
        }/>

        <Route path="/my/authentication/:authenticationId/status" element={<>
            <PublicHeader shadow={true}/>
            <Container className={"main-content"}>
                <PatientAuthenticationStatusPage/>
            </Container>
            <PublicFooter/>
        </>}/>

        <Route path="/my/bookings/:bookingId/authentication" element={
            <PatientRestricted>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <PatientBookingAuthenticationPage/>
                </Container>
                <PublicFooter/>
            </PatientRestricted>
        }/>

        <Route path="/my/bookings/:bookingId/platform-selection" element={
            <PatientRestricted>
                <PublicHeader shadow={true}/>
                <PlatformSelectionPage/>
                <PublicFooter/>

            </PatientRestricted>
        }/>

        <Route path="/my/bookings/:bookingId/platform-selection/mobile-qr" element={
            <PatientRestricted>
                <PublicHeader shadow={true}/>
                <MobileQrCodePage/>
                <PublicFooter/>
            </PatientRestricted>
        }/>

        <Route path="/my/bookings/:bookingId/call" element={
            <PatientRestricted>
                <PatientCallPage/>
            </PatientRestricted>
        }/>

        <Route path="/my/bookings/:bookingId/anesthesiology/consent-form" element={
            <PatientRestricted>
                <PublicHeader shadow={true}/>
                <main className="page-wrapper d-flex flex-column justify-content-between">
                    <AnesthesiologyPatientConsentPage/>
                </main>
                <PublicFooter/>
            </PatientRestricted>
        }/>

        <Route path="/my/bookings/:bookingId/anesthesiology/condition-form" element={
            <PatientRestricted>
                <PublicHeader shadow={false}/>
                <main className="page-wrapper d-flex flex-column justify-content-between">
                    <AnesthesiologyPatientConditionPage/>
                </main>
                <PublicFooter/>
            </PatientRestricted>
        }/>

        <Route path="/my/bookings/:bookingId/patient-info" element={<>
            <PatientRestricted>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <PatientBookingPatientInfoPage
                        nonGenericFormFactory={(formType, bookingId, navigate) => formType === FORM_ANESTHESIOLOGIST && <>
                            <AnesthesiologyPatientConditionCard
                                bookingId={bookingId}
                                cardBorder={true}
                            />
                            <AnesthesiologyPatientConsentCard
                                bookingId={bookingId}
                                bookingStatus={BOOKING_STATUS_OCCUPIED}
                                cardBorder={true}
                            />
                            <div className={"text-end"}>
                                <LocalizedLink className={"btn btn-light btn-lg"}
                                               to={PatientLinks.booking.summary(bookingId)}>
                                    <FormattedMessage id={"common.button.continue"}/> <i
                                    className={"ai ai-arrow-right text-decoration-none ms-2"}/>
                                </LocalizedLink>
                            </div>
                        </>}/>
                </Container>
                <DoctorFooter variant={'dark'}/>
            </PatientRestricted>
        </>}/>

        <Route path="/my/bookings/:bookingId" element={
            <PatientRestricted>
                <PublicHeader shadow={false}/>
                <PatientBookingPageV2
                    nonGenericFormFactory={(formType, booking, isEditingPatientCondition, setIsEditingPatientCondition, handlePatientConditionUpdate) => {
                        return formType === FORM_ANESTHESIOLOGIST &&
                            <AnesthesiologyPatientConditionCard bookingId={booking.id}/>
                    }}/>
                <DoctorFooter variant={'dark'}/>
            </PatientRestricted>
        }/>

        <Route path="/my/appointment-requests/:appointmentRequestId" element={
            <PatientRestricted>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <PatientAppointmentRequestPage/>
                </Container>
                <DoctorFooter variant={'dark'}/>
            </PatientRestricted>
        }/>

        <Route path="/my/bookings" element={
            <PatientRestricted>
                <PublicHeader/>
                <Container className={"main-content"}>
                    <PatientBookingsPage/>
                </Container>
                <DoctorFooter variant={'dark'}/>
            </PatientRestricted>
        }/>

        <Route path="/my/surveys/sirds-veseliba" element={
            <PatientRestricted>
                <div className={"main-content"}>
                    <PublicHeader shadow={true}/>
                    <LongenesisSurveyPage/>
                </div>
                <DoctorFooter variant={'dark'}/>
            </PatientRestricted>
        }/>

        <Route path="/my/surveys" element={
            <PatientRestricted>
                <div className={"main-content"}>
                    <PublicHeader shadow={true}/>
                    <LongenesisSurveysPage/>
                </div>
                <DoctorFooter variant={'dark'}/>
            </PatientRestricted>
        }/>

        <Route path="/doctors/:doctorId/bookings/:bookingId/payment/status" element={<>
            <PatientRestricted>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <PatientBookingPaymentStatusPageWrapper/>
                </Container>
                <DoctorFooter variant={'dark'}/>
            </PatientRestricted>
        </>}/>

        <Route path="/doctors/:doctorId/bookings/:bookingId/payment" element={<>
            <PatientRestricted>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <PatientBookingPaymentPageLvWrapper
                        initiateStripeCheckoutSession={PatientAppointmentsApi.initiateStripeCheckoutSession}
                        cancelLinkFactory={(doctorSlug) => PublicLinks.doctor(doctorSlug)}
                        showBreadcrumbs={true}
                    />
                </Container>
                <DoctorFooter variant={'dark'}/>
            </PatientRestricted>
        </>}/>

        <Route path="/doctors/:doctorId/appointments/:appointmentId" element={<>
            <PatientRestricted>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <AppointmentReservationPage
                        getAppointmentCall={AppointmentsApi.getAppointment}
                        reserveAppointmentCall={PatientAppointmentsApi.reserveAppointment}
                        showBreadcrumbs={true}
                    />
                </Container>
                <PublicFooter/>
            </PatientRestricted>
        </>}/>

        <Route path="/thank-you/email-verified" element={<>
            <PublicHeader shadow={true}/>
            <Container className={"main-content"}>
                <ThankYouEmailConfirmed/>
            </Container>
            <PublicFooter/>
        </>}/>

        <Route path="/thank-you/unsubscribed" element={<>
            <PublicHeader shadow={true}/>
            <Container className={"main-content"}>
                <ThankYouUnsubscribed/>
            </Container>
            <PublicFooter/>
        </>}/>

        <Route path="/doctors/:doctorId/request-appointment/confirm" element={<>
            <PatientRestricted>
                <PublicHeader shadow={true}/>
                <div className={"main-content"}>
                    <RecaptchaAware>
                        <RequestAppointmentConfirmation/>
                    </RecaptchaAware>
                </div>
                <PublicFooter/>
            </PatientRestricted>
        </>}/>

        <Route path="/doctors/:doctorId/request-appointment" element={<>
            <PatientRestricted>
                <PublicHeader shadow={true}/>
                <div className={"main-content"}>
                    <RequestAppointmentPage/>
                </div>
                <PublicFooter/>
            </PatientRestricted>
        </>}/>

        <Route path="/doctors/santa-liepa-avotkalna" element={<>
            <Navigate replace to={'/doctors/santa-kursaite'}/>
        </>}/>

        <Route path={getTemplate("doctor")} element={<>
            <PublicHeader shadow={true}/>
            <div className={"main-content d-flex flex-column"}>
                <SingleDoctorPage
                    carouselVideosDb={VIDEOS}
                    getDoctorCall={DoctorApi.getDoctor}
                    faqItems={[
                        faqItems.aboutMedon.howDoesMedonWork,
                        faqItems.remoteConsultations.whatTechnicalSolutionIsRequired,
                        faqItems.costs.whenDoIHaveToPay,
                        faqItems.remoteConsultations.howToPrepare,
                        faqItems.costs.canICancel,
                    ]}/>
            </div>
            <PublicFooter/>
        </>}/>

        <Route path={getTemplate("doctors")} element={<>
            <PublicHeader shadow={false}/>
            <main className="page-wrapper d-flex flex-column justify-content-between">
                <DoctorListPage howItWorksForDoctorsLink={PublicLinks.howItWorksForDoctors()}/>
                <DoctorListCta/>
            </main>
            <PublicFooter/>
        </>}/>

        <Route path={getTemplate("health-issues")} element={<>
            <PublicHeader shadow={false} bgClassName={"bg-secondary"}/>
            <main className="page-wrapper d-flex flex-column justify-content-between bg-secondary">
                <HealthIssuesPage
                    prefix={"pages.health-issues"}
                    itemsPrefix={"cms.health-issues"}
                    items={activeHealthIssues}
                    linkFactory={id => CommonPublicLinks.healthIssue(`health-issues.${id}`)}
                />
                <CtaSectionWithArrow
                    bg={"dark400"}
                    textClass={""}
                    title={<FormattedMessage id={"pages.health-issues.cta.title"}/>}
                    text={<FormattedMessage id={"pages.health-issues.cta.text"}/>}
                    buttonLink={PublicLinks.doctors()}
                    buttonText={<FormattedMessage id={"pages.doctor-specialities.cta.button"}/>}
                />
            </main>
            <PublicFooter/>
        </>}/>

        <Route path={getTemplate("health-issue")} element={<>
            <PublicHeader shadow={false}/>
            <main className="page-wrapper d-flex flex-column justify-content-between">
                <HealthIssueLocalizedPage
                    breadcrumbsRoot={{
                        link: CommonPublicLinks.healthIssues(),
                        text: <FormattedMessage id={'pages.health-issues.title'}/>,
                    }}
                    carouselVideosDb={VIDEOS}
                    prefix={'cms'}/>
            </main>
            <PublicFooter/>
        </>}/>

        <Route path={'/storyblok'} element={<>
            <PublicHeader shadow={false} bgClassName={"bg-secondary"}/>
            <main className="page-wrapper d-flex flex-column justify-content-between bg-secondary">
                <StoryblokFakeNavigationPage/>
            </main>
            <PublicFooter/>
        </>}/>

        <Route path={getTemplate("storyblok.health-issues")} element={<>
            <PublicHeader shadow={false} bgClassName={"bg-secondary"}/>
            <main className="page-wrapper d-flex flex-column justify-content-between bg-secondary">
                <HealthIssuesStoryblokPage/>
            </main>
            <PublicFooter/>
        </>}/>

        <Route path={getTemplate("storyblok.health-issue")} element={<>
            <PublicHeader/>
            <HealthIssueStoryblokPage/>
        </>}/>

        <Route path={"/veselibas-problemas/:parentSlug/:childSlug"} element={<>
            <PublicHeader bgClassName={"bg-secondary"} shadow={false}/>
            <SpecificHealthIssueStoryblokPage/>
            <PublicFooter/>
        </>}/>

        <Route path={'/storyblok/health-issue/:healthIssueId'} element={<>
            <PublicHeader/>
            <HealthIssueStoryblokPage/>
        </>}/>

        <Route path={getTemplate("storyblok.specialities")} element={<>
            <PublicHeader shadow={false} bgClassName={"bg-secondary"}/>
            <main className="page-wrapper d-flex flex-column justify-content-between bg-secondary">
                <SpecialitiesStoryblokPage/>
            </main>
            <PublicFooter/>
        </>}/>

        <Route path={getTemplate("storyblok.speciality")} element={<>
            <PublicHeader shadow={false}/>
            <StoryblokSpecialityPage slugPrefix={"specialities/"}/>
        </>}/>

        <Route path={'/storyblok/speciality/:specialityId'} element={<>
            <PublicHeader shadow={false}/>
            <StoryblokSpecialityPage slugPrefix={"specialities/"}/>
        </>}/>

        <Route path={'/sl/:landingId'} element={<>
            <StoryblokLandingPage slugPrefix={"landings/"}/>
        </>}/>

        <Route path={'/storyblok/landing/:landingId'} element={<>
            <StoryblokLandingPage slugPrefix={"landings/"}/>
        </>}/>

        <Route path={'/blog'} element={<>
            <PublicHeader bgClassName={"bg-white"} shadow={false}/>
            <BlogStoryblokPage/>
            <PublicFooter/>
        </>}/>

        <Route path={"/blog/:blogPostSlug"} element={<>
            <PublicHeader bgClassName={"bg-secondary"} shadow={false}/>
            <BlogPostStoryblokPage/>
            <PublicFooter/>
        </>}/>

        <Route path={getTemplate("about")} element={<>
            <AboutPage/>
            <PublicFooter/>
        </>}/>

        <Route path={getTemplate("contacts")} element={<>
            <PublicHeader shadow={false}/>
            <FullHeightWrapper>
                <ContactsPage/>
            </FullHeightWrapper>
        </>}/>

        <Route path={getTemplate("doctor-registration")} element={<>
            <PublicHeader/>
            <DoctorRegistrationStep1Page/>
            <PublicFooter/>
        </>}/>

        <Route path="/patient/registration" element={<>
            <PublicHeader/>
            <PatientRegistrationStep2Page/>
            <PublicFooter/>
        </>}/>

        <Route path="/faq/about-medon" element={<>
            <Navigate to={PublicLinks.faq()} replace={true}/>
        </>}/>

        <Route path="/faq/remote-consultations" element={<>
            <Navigate to={PublicLinks.faq()} replace={true}/>
        </>}/>

        <Route path="/faq/costs" element={<>
            <Navigate to={PublicLinks.faq()} replace={true}/>
        </>}/>

        <Route path={getTemplate("faq")} element={<>
            <PublicHeader shadow={false} bgClassName={"bg-secondary"}/>
            <div className={"bg-secondary"}>
                <Container className={"main-content"}>
                    <FaqPage commonItems={[
                        faqItems.aboutMedon.whatIsMedon,
                        faqItems.aboutMedon.howDoesMedonWork,
                        faqItems.aboutMedon.isMyPersonalInformationProtected,
                        faqItems.remoteConsultations.whatConsultationCanBeProvided,
                    ]}/>
                </Container>
            </div>
            <PublicFooter/>
        </>}/>

        <Route path={getTemplate("how-it-works")} element={<>
            <PublicHeader bgClassName={"bg-nude"} shadow={false}/>
            <HowItWorksPage/>
            <PublicFooter/>
        </>}/>

        <Route path="/sirdsmazspeja/apply" element={<>
            <Navigate replace to={PublicLinks.home()}/>
        </>}/>

        <Route path="/sirdsmazspeja" element={
            <Navigate replace to={PublicLinks.home()}/>
        }/>

        <Route path={PublicLinks.sirdsVeseliba()} element={
            <>
                <ParSirdiPage faqItems={[{
                    hash: 'for-doctors-q1',
                    titleMessage: {id: 'faq.for-doctors-q1.title'},
                    textMessage: {id: 'faq.for-doctors-q1.text'}
                }, {
                    hash: 'for-doctors-q2',
                    titleMessage: {id: 'faq.for-doctors-q2.title'},
                    textMessage: {id: 'faq.for-doctors-q2.text'}
                }, {
                    hash: 'for-doctors-q3',
                    titleMessage: {id: 'faq.for-doctors-q3.title'},
                    textMessage: {id: 'faq.for-doctors-q3.text'}
                }, {
                    hash: 'for-doctors-q4',
                    titleMessage: {id: 'faq.for-doctors-q4.title'},
                    textMessage: {id: 'faq.for-doctors-q4.text'}
                }, {
                    hash: 'for-doctors-q5',
                    titleMessage: {id: 'faq.for-doctors-q5.title'},
                    textMessage: {id: 'faq.for-doctors-q5.text'}
                }]}/>
                <PublicFooter/>
            </>}
        />

        <Route path="/sieviesu-sirds-veseliba" element={
            <>
                <SieviesuSirdsVeselibaPage faqItems={[{
                    hash: 'for-doctors-q1',
                    titleMessage: {id: 'faq.for-doctors-q1.title'},
                    textMessage: {id: 'faq.for-doctors-q1.text'}
                }, {
                    hash: 'for-doctors-q2',
                    titleMessage: {id: 'faq.for-doctors-q2.title'},
                    textMessage: {id: 'faq.for-doctors-q2.text'}
                }, {
                    hash: 'for-doctors-q3',
                    titleMessage: {id: 'faq.for-doctors-q3.title'},
                    textMessage: {id: 'faq.for-doctors-q3.text'}
                }, {
                    hash: 'for-doctors-q4',
                    titleMessage: {id: 'faq.for-doctors-q4.title'},
                    textMessage: {id: 'faq.for-doctors-q4.text'}
                }, {
                    hash: 'for-doctors-q5',
                    titleMessage: {id: 'faq.for-doctors-q5.title'},
                    textMessage: {id: 'faq.for-doctors-q5.text'}
                }]}/>
                <PublicFooter/>
            </>}
        />

        <Route path="/sieviesu-sirds-veseliba/apply" element={
            <>
                <PatientRestricted>
                    <PublicHeader shadow={true}/>
                    <Container className={"main-content"}>
                        <SieviesuSirdsVeselibaApplicationPage/>
                    </Container>
                    <PublicFooter/>
                </PatientRestricted>
            </>}
        />

        <Route path="/sieviesu-sirds-veseliba/already-exists" element={
            <>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <SieviesuSirdsVeselibaApplicationAlreadyExistsPage/>
                </Container>
                <PublicFooter/>
            </>}
        />

        <Route path="/sieviesu-sirds-veseliba/success" element={
            <>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <SieviesuSirdsVeselibaApplicationSuccessPage/>
                </Container>
                <PublicFooter/>
            </>}
        />


        <Route path="/viegli-but" element={
            <>
                <ViegliButPage/>
                <PublicFooter/>
            </>}
        />

        <Route path="/sirds-veselibas-tests/apply" element={
            <>
                <PatientRestricted>
                    <PublicHeader shadow={true}/>
                    <Container className={"main-content"}>
                        <LongenesisApplicationPage/>
                    </Container>
                    <PublicFooter/>
                </PatientRestricted>
            </>}
        />

        <Route path="/sirds-veselibas-tests/already-exists" element={
            <>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <ViegliButApplicationAlreadyExistsPage/>
                </Container>
                <PublicFooter/>
            </>}
        />

        <Route path="/sirds-veselibas-tests/success" element={
            <>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <ViegliButApplicationSuccessPage/>
                </Container>
                <PublicFooter/>
            </>}
        />


        <Route path="/viegli-but-petijums" element={
            <>
                <ViegliButPetijumsPage/>
                <PublicFooter/>
            </>}
        />

        <Route path="/viegli-but-petijums/apply" element={
            <>
                <PatientRestricted>
                    <PublicHeader shadow={true}/>
                    <Container className={"main-content"}>
                        <ViegliButPetijumsApplicationPage/>
                    </Container>
                    <PublicFooter/>
                </PatientRestricted>
            </>}
        />

        <Route path="/viegli-but-petijums/already-exists" element={
            <>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <ViegliButPetijumsApplicationAlreadyExistsPage/>
                </Container>
                <PublicFooter/>
            </>}
        />

        <Route path="/viegli-but-petijums/success" element={
            <>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <ViegliButPetijumsApplicationSuccessPage/>
                </Container>
                <PublicFooter/>
            </>}
        />

        <Route path="/viegli-but-petijums/failure" element={
            <>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <ViegliButPetijumsApplicationFailurePage/>
                </Container>
                <PublicFooter/>
            </>}
        />

        <Route path="/sirds-mazspeja" element={
            <>
                <SirdsMazspejaPage faqItems={[{
                    hash: 'for-doctors-q1',
                    titleMessage: {id: 'faq.for-doctors-q1.title'},
                    textMessage: {id: 'faq.for-doctors-q1.text'}
                }, {
                    hash: 'for-doctors-q2',
                    titleMessage: {id: 'faq.for-doctors-q2.title'},
                    textMessage: {id: 'faq.for-doctors-q2.text'}
                }, {
                    hash: 'for-doctors-q3',
                    titleMessage: {id: 'faq.for-doctors-q3.title'},
                    textMessage: {id: 'faq.for-doctors-q3.text'}
                }, {
                    hash: 'for-doctors-q4',
                    titleMessage: {id: 'faq.for-doctors-q4.title'},
                    textMessage: {id: 'faq.for-doctors-q4.text'}
                }, {
                    hash: 'for-doctors-q5',
                    titleMessage: {id: 'faq.for-doctors-q5.title'},
                    textMessage: {id: 'faq.for-doctors-q5.text'}
                }]}/>
                <PublicFooter/>
            </>}
        />

        <Route path="/sirds-mazspeja/apply" element={
            <>
                <PatientRestricted>
                    <PublicHeader shadow={true}/>
                    <Container className={"main-content"}>
                        <SirdsMazspejaApplicationPage/>
                    </Container>
                    <PublicFooter/>
                </PatientRestricted>
            </>}
        />

        <Route path="/sirds-mazspeja/already-exists" element={
            <>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <SirdsMazspejaApplicationAlreadyExistsPage/>
                </Container>
                <PublicFooter/>
            </>}
        />

        <Route path="/sirds-mazspeja/success" element={
            <>
                <PublicHeader shadow={true}/>
                <Container className={"main-content"}>
                    <SirdsMazspejaApplicationSuccessPage/>
                </Container>
                <PublicFooter/>
            </>}
        />

        <Route path="/sirdsveselibas-tests" element={<>
            <Navigate replace to={PublicLinks.sirdsVeselibasTests()}/>
        </>}/>

        <Route path={getTemplate("cardiac-health-test")} element={<>
            <CardioTestPromotionPage/>
            <PublicFooter/>
        </>}/>

        <Route path="/instructions/camera-mic-access" element={<>
            <PublicHeader shadow={false} bgClassName={"bg-secondary"}/>
            <CameraMicAccessInstructions/>
            <PublicFooter/>
        </>}/>

        <Route path={getTemplate("how-it-works-for-doctors")} element={<>
            <HowItWorksForDoctorPage faqItems={[{
                hash: 'for-doctors-q1',
                titleMessage: {id: 'faq.for-doctors-q1.title'},
                textMessage: {id: 'faq.for-doctors-q1.text'}
            }, {
                hash: 'for-doctors-q2',
                titleMessage: {id: 'faq.for-doctors-q2.title'},
                textMessage: {id: 'faq.for-doctors-q2.text'}
            }, {
                hash: 'for-doctors-q3',
                titleMessage: {id: 'faq.for-doctors-q3.title'},
                textMessage: {id: 'faq.for-doctors-q3.text'}
            }, {
                hash: 'for-doctors-q4',
                titleMessage: {id: 'faq.for-doctors-q4.title'},
                textMessage: {id: 'faq.for-doctors-q4.text'}
            }, {
                hash: 'for-doctors-q5',
                titleMessage: {id: 'faq.for-doctors-q5.title'},
                textMessage: {id: 'faq.for-doctors-q5.text'}
            },]}/>
            <PublicFooter/>
        </>}/>

        <Route path={getTemplate("for-foreign-patients")} element={<>
            <PublicHeader shadow={false} bgClassName={"bg-secondary"}/>
            <ForeignPatientsLanding
                faqItems={[
                    faqItems.aboutMedon.whatIsMedon,
                    faqItems.aboutMedon.isMyPersonalInformationProtected,
                    faqItems.remoteConsultations.whatConsultationCanBeProvided,
                    faqItems.remoteConsultations.whatTechnicalSolutionIsRequired,
                ]}/>
            <PublicFooter/>
        </>}/>

        <Route path="/how-it-works-for-doctor-video" element={<>
            <HowItWorksForDoctorVideoPage faqItems={[{
                hash: 'for-doctors-q1',
                titleMessage: {id: 'faq.for-doctors-q1.title'},
                textMessage: {id: 'faq.for-doctors-q1.text'}
            }, {
                hash: 'for-doctors-q2',
                titleMessage: {id: 'faq.for-doctors-q2.title'},
                textMessage: {id: 'faq.for-doctors-q2.text'}
            }, {
                hash: 'for-doctors-q3',
                titleMessage: {id: 'faq.for-doctors-q3.title'},
                textMessage: {id: 'faq.for-doctors-q3.text'}
            }, {
                hash: 'for-doctors-q4',
                titleMessage: {id: 'faq.for-doctors-q4.title'},
                textMessage: {id: 'faq.for-doctors-q4.text'}
            }, {
                hash: 'for-doctors-q5',
                titleMessage: {id: 'faq.for-doctors-q5.title'},
                textMessage: {id: 'faq.for-doctors-q5.text'}
            },]}/>
            <PublicFooter/>
        </>}/>

        {/**
         *
         * Mostly doctor pages
         *
         */}

        <Route path="/doctor/patients" element={
            <DefaultDoctorLayout>
                <PatientsPage/>
            </DefaultDoctorLayout>
        }/>

        <Route path="/doctor/patient/:patientId" element={
            <DefaultDoctorLayout>
                <PatientPage/>
            </DefaultDoctorLayout>
        }/>

        <Route path="/doctor/appointments" element={
            <DefaultDoctorLayout>
                <DoctorAppointmentsPage/>
            </DefaultDoctorLayout>
        }/>

        <Route path="/doctor/schedules" element={
            <DefaultDoctorLayout>
                <DoctorSchedulesPage/>
            </DefaultDoctorLayout>
        }/>

        <Route path="/doctor/calendar" element={
            <DefaultDoctorLayout>
                <CalendarPage/>
            </DefaultDoctorLayout>
        }/>

        <Route path="/doctor/my/*" element={
            <DefaultDoctorLayout>
                <DoctorProfilePage/>
            </DefaultDoctorLayout>
        }/>

        <Route path="/doctor/booking/:bookingId/anesthesiologist-form" element={
            <DefaultDoctorLayout>
                <AnesthesiologistDischargePage/>
            </DefaultDoctorLayout>
        }/>

        <Route path="/doctor/booking/:bookingId" element={
            <DefaultDoctorLayout>
                <DoctorAppointmentPage/>
            </DefaultDoctorLayout>
        }/>

        <Route path="/doctor/appointment-request/:appointmentRequestId" element={
            <DefaultDoctorLayout>
                <AppointmentRequestPage/>
            </DefaultDoctorLayout>
        }/>

        <Route path="/doctor/call/:bookingId" element={
            <FullscreenLayout>
                <DoctorCallPage/>
            </FullscreenLayout>
        }/>

        <Route path="/doctor/registration/experience-and-education" element={
            <DefaultDoctorLayout showContinueRegistrationAlert={false}>
                <ExperienceAndEducationStepPage/>
            </DefaultDoctorLayout>
        }/>

        <Route path="/doctor/registration/photo-upload" element={
            <DefaultDoctorLayout showContinueRegistrationAlert={false}>
                <PhotoUploadStepPage/>
            </DefaultDoctorLayout>
        }/>

        <Route path="/doctor/registration" element={<>
            <PublicHeader/>
            <Container>
                <DoctorRegistrationPage/>
            </Container>
            <PublicFooter/>
        </>}/>

        <Route path="/doctor" element={
            <DefaultDoctorLayout>
                <DoctorDashboardPage/>
            </DefaultDoctorLayout>
        }/>

        {/**
         * Common routes
         */}
        <Route path="/home-v2" exact element={
            <main className="page-wrapper">
                <HomePageV2 faqItems={[
                    faqItems.aboutMedon.whatIsMedon,
                    faqItems.aboutMedon.isMyPersonalInformationProtected,
                    faqItems.remoteConsultations.whatConsultationCanBeProvided,
                    faqItems.remoteConsultations.whatTechnicalSolutionIsRequired,
                ]}/>
                <PublicFooter/>
            </main>
        }/>

        <Route path="/partners/login" element={<>
            <PartnerLoginPage/>
        </>}/>

        <Route path="/partners/dashboard" element={<PartnerRestricted>
            <PartnerEntryPointPage pageName={pages.DOCTOR_DASHBOARD}/>
        </PartnerRestricted>}/>

        <Route
            path="/partners/bookings/:bookingId/call-participant-doctors/:doctorParticipantId/call"
            element={<>
                <PartnerEntryPointPage pageName={pages.DOCTOR_CALL_PAGE}/>
            </>}/>

        <Route
            path="/partners/bookings/:bookingId/call-participant-patients/:participantPatientId"
            element={<>
                <PartnerEntryPointPage pageName={pages.PATIENT_WAITING_PAGE}/>
            </>}/>

        <Route
            path="/partners/bookings/:bookingId/call-participant-patients/:participantPatientId/call"
            element={<>
                <PartnerEntryPointPage pageName={pages.PATIENT_CALL_PAGE}/>
            </>}/>

        <Route
            path="/partners/bookings/:bookingId/call-participant-patients/:participantPatientId/call-check"
            element={<>
                <PartnerEntryPointPage pageName={pages.PATIENT_CHECK_CALL_PAGE}/>
            </>}/>

        <Route path="/internet-aptieka" exact element={<>
            <PublicHeader/>
            <InternetAptiekaPromoPage/>
            <PublicFooter/>
        </>}/>

        <Route path="/" exact element={
            <main className="page-wrapper">
                <HomePageV2 faqItems={[
                    faqItems.aboutMedon.whatIsMedon,
                    faqItems.aboutMedon.isMyPersonalInformationProtected,
                    faqItems.remoteConsultations.whatConsultationCanBeProvided,
                    faqItems.remoteConsultations.whatTechnicalSolutionIsRequired,
                ]}/>
                <PublicFooter/>
            </main>
        }/>

        <Route path="*" strict status={404} element={<>
            <PublicHeader/>
            <Container className={"main-content"}>
                <NotFoundPage/>
            </Container>
            <PublicFooter/>
        </>}/>
    </Routes>
}

let PatientBookingPaymentStatusPageWrapper = () => {
    let {bookingId} = useParams()

    return <PatientBookingPaymentStatusPage
        successRedirectLink={CommonPatientLinks.booking.patientInfo(bookingId)}
    />
}

export default App;

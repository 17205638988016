import {POSTHOG_SHOW_SURVEY} from "@commons/infra/posthog/redux/actions";

let initialState = {
    surveyIdToShow: null,
};

export default function posthogReducer(state = initialState, action) {
    switch (action.type) {
        case POSTHOG_SHOW_SURVEY:
            return {
                ...state,
                surveyIdToShow: action.payload.surveyId
            };
        default:
            return state
    }
}

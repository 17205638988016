import {Helmet} from "react-helmet";
import {useLocation, useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useLocalizedRoutes} from "@commons/infra/i18n/routes";
import {LANGUAGE_CODE_ENGLISH, LANGUAGE_CODE_LATVIAN, LANGUAGE_CODE_RUSSIAN} from "@commons/infra/i18n/consts";
import {deleteQueryParams} from "@commons/infra/helpers";

export let MetaLinksHelmet = ({ignoredQueryParams = [], alternateLanguages = [], defaultLanguageCode}) => {
    let {pathname, search, key} = useLocation();
    let {locale} = useIntl()
    let [canonicalUrl, setCanonicalUrl] = useState();
    let [alternateLinks, setAlternateLinks] = useState([]);
    let params = useParams()
    let lr = useLocalizedRoutes()

    useEffect(() => {
        // Alternate links
        //
        // For each variation of the page, include a set of <link> elements in the <head> element,
        // one link for each page variant ___including itself___.
        //
        // https://developers.google.com/search/docs/specialty/international/localized-versions
        let alternativeLinks = [...alternateLanguages, defaultLanguageCode].map(l => ({
            code: l,
            url: deleteQueryParams(ignoredQueryParams, window.CONFIG.FRONTEND_BASE_URL + lr.getCurrentUrl(l))
        }));
        setAlternateLinks(alternativeLinks)

        // Canonical
        setCanonicalUrl(deleteQueryParams(ignoredQueryParams, window.CONFIG.FRONTEND_BASE_URL + lr.getCurrentUrl('lv')))
    }, [alternateLanguages, locale, pathname, search, params, key]);

    return <>
        <Helmet>
            <link rel="canonical" href={canonicalUrl}/>
            {alternateLinks.map(al => <link rel="alternate" href={al.url} hrefLang={al.code}/>)}
            {window.CONFIG.SEO.noindex && <meta name="robots" content="noindex"/>}
        </Helmet>
    </>
}

export let LatvianMetaLinksHelmet = () => <MetaLinksHelmet
    alternateLanguages={[LANGUAGE_CODE_RUSSIAN, LANGUAGE_CODE_ENGLISH]}
    ignoredQueryParams={["rp"]}
    defaultLanguageCode={LANGUAGE_CODE_LATVIAN}
/>

import React, {useEffect, useState} from "react";
import {EMPTY_FUNCTION, uuidv4} from "../helpers";
import {FormattedMessage} from "react-intl";
import {LodashFillIns} from "@commons/lodash";

export let DOW_MONDAY = 'MONDAY'
export let DOW_TUESDAY = 'TUESDAY'
export let DOW_WEDNESDAY = 'WEDNESDAY'
export let DOW_THURSDAY = 'THURSDAY'
export let DOW_FRIDAY = 'FRIDAY'
export let DOW_SATURDAY = 'SATURDAY'
export let DOW_SUNDAY = 'SUNDAY'

/**
 * Used to convert DateTime#toFormat('E') to internal constant
 * @param number
 * @returns {string}
 */
export let dowFromNumber = (number) => {
    switch (number) {
        case '1':
            return DOW_MONDAY;
        case '2':
            return DOW_TUESDAY;
        case '3':
            return DOW_WEDNESDAY;
        case '4':
            return DOW_THURSDAY;
        case '5':
            return DOW_FRIDAY;
        case '6':
            return DOW_SATURDAY;
        case '7':
            return DOW_SUNDAY;
        default:
            throw new Error()
    }
}

export let dows = [
    DOW_MONDAY,
    DOW_TUESDAY,
    DOW_WEDNESDAY,
    DOW_THURSDAY,
    DOW_FRIDAY,
    DOW_SATURDAY,
    DOW_SUNDAY
]

let dowsLabelValuePair = [
    {
        label: 'P',
        value: DOW_MONDAY
    },
    {
        label: 'O',
        value: DOW_TUESDAY
    },
    {
        label: 'T',
        value: DOW_WEDNESDAY
    },
    {
        label: 'C',
        value: DOW_THURSDAY
    },
    {
        label: 'P',
        value: DOW_FRIDAY
    },
    {
        label: 'S',
        value: DOW_SATURDAY
    },
    {
        label: 'Sv',
        value: DOW_SUNDAY
    },
];

// Must stay DOW widget for doc portal!!
let Days = ({
                               onUpdate = EMPTY_FUNCTION,
                               value = [],
                               items = dowsLabelValuePair,
                               editable = true,
                               dowClassName,
                               showHeader = true
                           }) => {
    let initialSelected = {}
    value.forEach(v => {
        initialSelected[v] = true
    })
    let [selected, setSelected] = useState(initialSelected)

    useEffect(() => {
        const arrayOfSelectedDays = Object.entries(selected)
            .filter(([, value]) => value)
            .map(([key]) => key);

        onUpdate(arrayOfSelectedDays)
    }, [selected])

    return <div className={"request-appointments-dows-container"}>
        {showHeader && <div className={"d-flex mb-2 justify-content-around fw-bold"}>
            <div><FormattedMessage id={"common.dows.short.1"}/></div>
            <div><FormattedMessage id={"common.dows.short.2"}/></div>
            <div><FormattedMessage id={"common.dows.short.3"}/></div>
            <div><FormattedMessage id={"common.dows.short.4"}/></div>
            <div><FormattedMessage id={"common.dows.short.5"}/></div>
            <div><FormattedMessage id={"common.dows.short.6"}/></div>
            <div><FormattedMessage id={"common.dows.short.7"}/></div>
        </div>}
        {LodashFillIns.chunk(items, 7).map(chunk =>
            <div className={"d-flex mb-2"}>
                {chunk.map(item =>
                    <DayCheckbox
                        label={item.label}
                        value={item.value}
                        selected={selected[item.value]}
                        setter={newValue => setSelected({...selected, [item.value]: newValue})}
                        editable={editable && !item.disabled}
                        className={dowClassName}
                    />)}
            </div>
        )}
    </div>
}

let DayCheckbox = ({
                             label, value, setter, selected, editable = true, dowClassName = ''
                         }) => {
    // <input>s must have unique IDs in case there will be multiple DaysOfTheWeekWidget on the page
    let [id] = useState(`${uuidv4()}-dow-${value}`)

    return <div className={"custom-checkbox-1 flex-grow-1 p-0 form-option-size me-1 " + dowClassName}>
        <input className="btn-check"
               type="checkbox"
               checked={selected}
               id={id}
               disabled={!editable}
               onChange={e => setter(e.target.checked)}/>
        <label className="form-option-label btn btn-outline-secondary px-0 " htmlFor={id}>
            {label}
        </label>
    </div>
}

export default Days


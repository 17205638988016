import React from "react";

export let breakpoint = 'md';

export let PageHeading = ({children}) => {
    return <h1 className={`mt-3 mb-2 mt-${breakpoint}-6 mb-${breakpoint}-5`}>{children}</h1>
}

export let Container = ({children, className = ''}) => {
    return <div className={`container mt-5 mt-${breakpoint}-6 pb-5 mb-4 ${className}`}>
        {children}
    </div>
}
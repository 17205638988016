import {useEffect} from "react";

export let ParallaxJs = () => {
    useEffect(() => {
        window.addEventListener('load', () => {
            import("../../../node_modules/parallax-js/dist/parallax.min.js").then((module) => {
                const element = document.querySelectorAll('.parallax')
                /* eslint-disable no-unused-vars, no-undef */
                for (let i = 0; i < element.length; i++) {
                    const parallaxInstance = new module.default(element[i])
                }
                /* eslint-enable no-unused-vars, no-undef */
            });
        })
    }, []);

    return <></>
}
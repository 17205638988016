import {GenericSection1} from "@commons/uinew/domainsection/textual/GenericSection1";
import {RichText} from "@commons/infra/storyblok/richText";
import {SectionWrapper} from "@commons/infra/storyblok/components/SectionWrapper";
import React from "react";
import {extractImageDimensions} from "@commons/infra/storyblok/helpers";

export let RowV2 = ({blok}) => {
    let imgDimensions = extractImageDimensions(blok.Illustration.filename)

    return <SectionWrapper differentColor={blok.IsDifferentColorSection}>
        <GenericSection1
            title={blok.Title}
            text={<RichText content={blok.Content}/>}
            imgSrc={blok.Illustration.filename}
            imgAlt={blok.Illustration.alt}
            imgHeight={imgDimensions.height}
            imgWidth={imgDimensions.width}
            imgSideClassName={blok.ImgSideBackgroundClass ? `${blok.ImgSideBackgroundClass} rounded-3` : ''}
            hideImageOnMobile={false}
            contentOnRightSide={blok.IllustrationPosition === 'left'}
        />
    </SectionWrapper>
}
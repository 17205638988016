import {currentPath} from "@commons/infra/helpers";

export let PatientOnboardingLinks = {
    step1: () => '/new-registration/1',
    step1ConfirmEmail: (email) => `/new-registration/1/confirm-email?email=${email}`,
    completeRegistration: (emailVerificationType, code, sid) => `/new-registration/finalize?email_verification_type=${emailVerificationType}&code=${code}&sid=${sid}`,
    onboarding: {
        controller: (setRedirect = false) => {
            let redirect = null;
            if (setRedirect) {
                redirect = encodeURIComponent(currentPath())
            }
            return `/new-registration/onboarding-controller${redirect ? `?rp=${redirect}` : ''}`
        },
        authentication: () => '/onboarding/authentication',
        phoneNumberVerification: {
            step1: () => '/onboarding/phone-number',
            step2: (fullPhoneNumber) => `/onboarding/phone-number/verify?p=${encodeURIComponent(fullPhoneNumber)}`,
        }
    },
}
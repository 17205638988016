import React from "react";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";
import CtaSectionWithArrow from "@commons/uinew/domainsection/cta/CtaSectionWithArrow";

let Cta = ({blok}) => <CtaSectionWithArrow
    className={blok.BackgroundClass}
    title={blok.Title}
    text={blok.Text}
    buttonLink={parseI18nLink(blok.ButtonLink)}
    buttonText={blok.ButtonText}
/>

export default Cta
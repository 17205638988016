import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {PublicLinks} from "../../../../portal-latvia/src/utility/links";

export let StatisticsSectionV1 = ({text, ctaText, ctaLink, items}) => {
    return <div className="row">
        <div className="col-lg-5 offset-xl-1 mb-2 mb-lg-0 order-lg-1">
            <div className="h1 text-center text-lg-start pb-md-2 pb-xl-0 mb-2 mb-xl-3">
                {text}
            </div>
            <Cta showOnMobile={false} text={ctaText} link={ctaLink}/>
        </div>
        <div className="col-lg-7 col-xl-6 order-lg-0 py-3 py-lg-0">
            <div className="row row-cols-2 row-cols-sm-2 g-3 g-md-4">
                <div className="col">
                    <div className="d-flex flex-column h-100 bg-success bg-opacity-10 rounded-3 text-center p-4">
                        <div className="h3 display-5 mt-1 mb-0">{items[0].title}</div>
                        <div className="fs-lg mb-1">{items[0].text}</div>
                    </div>
                </div>
                <div className="col">
                    <div className="d-flex flex-column h-100 bg-info bg-opacity-10 rounded-3 text-center p-4">
                        <div className="h3 display-5 mt-1 mb-0">{items[1].title}</div>
                        <div className="fs-lg mb-1">{items[1].text}</div>
                    </div>
                </div>
                <div className="col">
                    <div className="d-flex flex-column h-100 bg-warning bg-opacity-10 rounded-3 text-center p-4">
                        <div className="h3 display-5 mt-1 mb-0">{items[2].title}</div>
                        <div className="fs-lg mb-1">{items[2].text}</div>
                    </div>
                </div>
                <div className="col">
                    <div className="d-flex flex-column h-100 bg-danger bg-opacity-10 rounded-3 text-center p-4">
                        <div className="h3 display-5 mt-1 mb-0">{items[3].title}</div>
                        <div className="fs-lg mb-1">{items[3].text}</div>
                    </div>
                </div>
            </div>
        </div>
        <Cta showOnMobile={true} text={ctaText} link={ctaLink}/>
    </div>
}

let Cta = ({showOnMobile, text, link}) => {
    let visibilityClassName = showOnMobile ? 'd-block d-lg-none' : 'd-none d-lg-block';
    return <div className={`${visibilityClassName} text-center text-lg-start`}>
        <LocalizedLink to={PublicLinks.doctors()} className={"btn btn-lg btn-link ps-0"}>
            {text} <i className={"ai ai-arrow-right ms-2"}/>
        </LocalizedLink>
    </div>
}
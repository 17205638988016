import {LANGUAGE_CODE_ENGLISH, LANGUAGE_CODE_LATVIAN, LANGUAGE_CODE_RUSSIAN} from "@commons/infra/i18n/LocalizedRouter";
import {alphabetSort, getKeyByValue} from "@commons/infra/helpers";
import {useIntl} from "react-intl";
import {PublicLinks} from "../../../../portal-latvia/src/utility/links";
import data from "../../../../portal-latvia/src/db/sb/_routes.json";

export let useStoryblokMapping = () => {
    let {locale} = useIntl()

    return StoryblokMapping(STORYBLOK_MAPPING, locale)
}

export let StoryblokMapping = (mapping, locale) => ({
    /**
     * @return Storyblok blok slug by Medon URL slug
     */
    resolveStoryblokSlug: (slug) => getKeyByValue(mapping.i18nKeys[locale], slug).replace("route-parts.storyblok.", ""),

    getSpecialities: () => mapping
        .specialities
        .map(item => ({
            title: item.translatable[locale].title,
            slug: item.translatable[locale].slug,
            link: PublicLinks.storyblokSpeciality(item.routeId),
            routeId: item.routeId
        }))
        .sort((a, b) => alphabetSort(a.routeId, b.routeId)),


    getSpeciality: (routeId) => {
        let found = mapping
            .specialities
            .find(item => item.routeId === routeId)

        return {
            title: found.translatable[locale].title,
            slug: found.translatable[locale].slug,
            link: PublicLinks.storyblokSpeciality(found.routeId),
            routeId: found.routeId
        }
    },

    getHealthIssues: () => mapping
        .healthIssues
        .map(item => ({
            title: item.translatable[locale].title,
            link: PublicLinks.storyblokHealthIssue(item.routeId),
            routeId: item.routeId
        }))
        .sort((a, b) => alphabetSort(a.routeId, b.routeId)),

    getSpecificHealthIssues: () => mapping
        .specificHealthIssues
        .sort()
        .map(item => ({
            title: item.translatable[locale].title,
            link: PublicLinks.storyblokSpecificHealthIssue(item.routeId, true),
            routeId: item.routeId
        }))
        .sort((a, b) => alphabetSort(a.routeId, b.routeId)),

    getLatvianLandings: () => mapping
        .landings
        .map(item => ({
            title: item.translatable[locale].title,
            link: PublicLinks.storyblokLatvianLanding(item.routeId, true),
            routeId: item.routeId
        }))
        .sort((a, b) => alphabetSort(a.routeId, b.routeId))
})

export let storyblokRoutePart = (key) => `storyblok.${key}`

/**
 * Converts data from our backend to frontend-friendly format
 */
export let convertStoryblokData = (storyblokData) => {
    let i18nKeys = [];
    let healthIssues = [];
    let specificHealthIssues = [];
    let specialities = [];
    let landings = [];

    [(LANGUAGE_CODE_LATVIAN), (LANGUAGE_CODE_RUSSIAN), (LANGUAGE_CODE_ENGLISH)].forEach(l => {
        i18nKeys[l] = {}
        Object.entries(storyblokData.routesMapping[l]).map(([key, value]) => {
            i18nKeys[l][`route-parts.${storyblokRoutePart(key)}`] = `${value}`
        })
    })

    storyblokData.healthIssues.map(value => {
        healthIssues.push(value)
    })

    storyblokData.specificHealthIssues.map(value => {
        specificHealthIssues.push(value)
    })

    storyblokData.specialities.map(value => {
        specialities.push(value)
    })

    storyblokData.landings.map(value => {
        landings.push(value)
    })

    return {i18nKeys, healthIssues, specificHealthIssues, specialities, landings}
}

export let STORYBLOK_MAPPING = convertStoryblokData(data)
export let getStoryblokKeysMapping = () => STORYBLOK_MAPPING.i18nKeys

// export let SPECIALITY_DERMATOR


export let SPECIALITY_DERMATOLOGS = 'dermatologs'
export let SPECIALITY_ENDOKRINOLOGS = 'endokrinologs'
export let SPECIALITY_GIMENES_ARSTS = 'gimenes-arsts'
export let SPECIALITY_GINEKOLOGS = 'ginekologs'
export let SPECIALITY_KARDIOLOGS = 'kardiologs'
export let SPECIALITY_NEIROLOGS = 'neirologs'
export let SPECIALITY_ONKOLOGS = 'onkologs'
export let SPECIALITY_PEDIATRS = 'pediatrs'
export let SPECIALITY_PSIHISKAS_VESELIBAS_SPECIALISTI = 'psihiskas-veselibas-specialisti'
export let SPECIALITY_REIMATOLOGS = 'reimatologs'
export let SPECIALITY_UROLOGS = 'urologs'
export let SPECIALITY_UZTURA_SPECIALISTS = 'uztura-specialists'
export let SPECIALITY_TRAUMOTOLOGS = 'traumatologs'

export const statusConfig = {
    CREATED: 'Izveidota',
    ACTIVE: 'Notiek',
    FINISHED: 'Beigusies',
    EXPIRED: 'Nav notikusi',
};

export const getStatusDisplay = (status) => {
    return statusConfig[status] || statusConfig['FINISHED'];
}

export const pages = {
    DOCTOR_DASHBOARD: 'DOCTOR_DASHBOARD',
    DOCTOR_CALL_PAGE: 'DOCTOR_CALL_PAGE',
    PATIENT_WAITING_PAGE: 'PATIENT_WAITING_PAGE',
    PATIENT_CALL_PAGE: 'PATIENT_CALL_PAGE',
    PATIENT_CHECK_CALL_PAGE: 'PATIENT_CHECK_CALL_PAGE',
}

import React from 'react';

let Logo = ({theme = 'dark', className = "", lazyLoading}) => <>
    <img src={`/img/medon-logo-${theme}.svg`}
         alt={"medon logo"}
         loading={lazyLoading ? "lazy" : "eager"}
         width={86}
         height={19}
         style={{width: 86, minWidth: 86, paddingBottom: 4, marginRight: 34}}
         className={className}/>
</>

export default Logo;

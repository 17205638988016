import React from "react";
import {breakpoint} from "../layout/common";
import {H2} from "../component/Text";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";

export let FullHeightWrapper = ({children, className}) => {
    return <main className={`page-wrapper d-flex flex-column ${className}`}>
        <div className="w-100 flex-full-height">
            {children}
        </div>
    </main>
}

/**
 * Base
 */
export let FullWidthSection = ({children, className = '', containerClass = '', padding = "lg"}) => {
    let paddingClass = "";
    switch (padding) {
        case "lg":
            paddingClass = "pt-4 pb-3 py-md-6 py-lg-7";
            break;
        case "md":
            paddingClass = "py-3 py-md-4 py-lg-5";
            break;
        case "sm":
            paddingClass = "py-2 py-md-3 py-lg-4";
            break;
        default:
            break;
    }

    return <div className={className}>
        <section className={`container ${paddingClass} ${containerClass}`}>
            {children}
        </section>
    </div>
}

/**
 * Full width section with title and generic content
 */
export let FullWidthSection1 = ({
                                    className,
                                    containerClass,
                                    padding = 'lg',
                                    HeadingRow = CenteredHeadingRow1,
                                    HeadingTag = H2,
                                    title,
                                    centerTitle,
                                    rowClass,
                                    children
                                }) => {
    return <FullWidthSection className={className} padding={padding} containerClass={containerClass}>
        {title && <HeadingRow>
            <HeadingTag centered={centerTitle}>{title}</HeadingTag>
        </HeadingRow>}

        <div className={`row pt-2 ${rowClass}`}>
            {children}
        </div>
    </FullWidthSection>
}

export let CenteredHeadingRow1 = ({children}) => {
    return <div className="row">
        <div className="col-md-12">
            {children}
        </div>
    </div>
}
export let CenteredHeadingRow2 = ({children}) => {
    return <div className="row justify-content-center pt-2 pt-sm-4 pb-4 mb-2 mb-lg-3">
        <div className="col-lg-8 col-xl-7 col-xxl-6 text-center pt-2">
            {children}
        </div>
    </div>
}

export let H1FromTemplate = ({children}) => {
    // return <div className="justify-content-center pt-2 pt-sm-4 pb-4 mb-2 mb-lg-3">
    //     <div className="col-lg-8 col-xl-7 col-xxl-6 text-center pt-2">
    return <h2 className="h1 mb-1 text-center">{children}</h2>
    // </div>
    // </div>
}

/**
 * Full width section with title and generic content
 */
export let FullWidthPage = ({title, rowClass = '', children}) => {
    return <div className={"main-content pb-md-6"}>
        <div className={"bg-secondary"}>
            <div className={`container pt-45 pb-3 pt-md-6 pb-md-5`}>
                <h1>{title}</h1>
            </div>
        </div>

        <section className={`container pt-45 pb-3 py-md-4`}>
            <div className={`row pt-2 ${rowClass}`}>
                {children}
            </div>
        </section>
    </div>
}

/**
 * 2 column section, picture + text, vertically centered
 */
export let FullWidthSection2 = ({
                                    className,
                                    imgSrc,
                                    imgAlt,
                                    imgHeight,
                                    imgWidth,
                                    hideImageOnMobile,
                                    imgClassName,
                                    title,
                                    padding,
                                    centerTitle = true,
                                    content,
                                    contentOnRightSide = true
                                }) => {
    return <FullWidthSection className={className} padding={padding}>
        <div className={`row align-items-center`}>
            <div
                className={`col-md-5 col-lg-5 mb-3 offset-lg-${contentOnRightSide ? '0' : '1'} text-center mt-n3 mt-md-0 px-7 px-lg-0 ${hideImageOnMobile && 'd-none d-md-block'} order-0 order-lg-${contentOnRightSide ? '0' : '1'}`}>
                <img className={imgClassName}
                     src={imgSrc}
                     alt={imgAlt}
                     height={imgHeight}
                     width={imgWidth}
                     style={{maxHeight: '420px'}}/>
            </div>

            <div
                className={`col-md-7 col-lg-6 offset-lg-${contentOnRightSide ? '1' : '0'} py-0 py-md-5 order-0 order-1 order-lg-${contentOnRightSide ? '1' : '0'}`}>
                {title && <H2 centered={centerTitle}>
                    {title}
                </H2>}

                {content}
            </div>
        </div>
    </FullWidthSection>
}

/**
 * Call to action section
 */
export let CtaSection = ({
                             className = '',
                             theme = 'light',
                             title,
                             text,
                             buttonText,
                             buttonLink,
                             buttonClass = 'btn-primary',
                             isButtonLinkExternal = false
                         }) => {
    let headingClass = theme === 'dark' ? 'text-white' : '';
    let textClass = theme === 'dark' ? 'text-white opacity-75' : '';

    return <FullWidthSection className={className}>
        <div className={`row text-center col-${breakpoint}-10 offset-${breakpoint}-1`}>
            <div className={"col-12"}>
                <h2 className={`display-6 ${headingClass}`}>
                    {title}
                </h2>
            </div>
            <div className={`mb-3 pb-2 fs-lg mt-2 ${textClass}`}>
                {text}
            </div>
            <div className="col-xl-4 offset-xl-4 col-md-6 offset-md-3">
                <div className="text-center mb-3">
                    <div className={`d-block d-${breakpoint}-none`}>
                        <LocalizedLink to={buttonLink}
                                       className={`btn btn-md ${buttonClass} px-4 rounded-3`}>
                            {buttonText}
                        </LocalizedLink>
                    </div>
                    <div className={`d-none d-${breakpoint}-block`}>
                        {!isButtonLinkExternal && <LocalizedLink to={buttonLink}
                                                                 className={`btn btn-lg ${buttonClass} px-4 rounded-3`}>
                            {buttonText}
                        </LocalizedLink>}
                        {isButtonLinkExternal && <a href={buttonLink} target={"_blank"} rel="noreferrer"
                                                    className={`btn btn-lg ${buttonClass} px-4 rounded-3`}>
                            {buttonText}
                        </a>}
                    </div>
                </div>
            </div>
        </div>
    </FullWidthSection>
}

export let CtaSectionWithArrow = ({
                                      bg = "dark",
                                      textClass = 'text-light',
                                      title,
                                      text,
                                      buttonText,
                                      buttonLink,
                                      buttonClass = 'btn-primary',
                                  }) => {

    return <FullWidthSection className={`bg-${bg}`}>
        <div className="container pb-1 pb-sm-3">
            <div className="row pt-sm-3 pt-md-4">
                <div className="col-md-6 col-xl-5 offset-xl-1 d-flex align-items-center justify-content-center">
                    <h2 className={`display-6 ${textClass}`}>
                        {title}
                    </h2>
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4 offset-lg-1">
                    <p className={`fs-xl pb-4 mb-2 mb-lg-3 ${textClass}`}>
                        {text}
                    </p>
                    <div className="d-sm-flex align-items-center">
                        <LocalizedLink className={`btn btn-lg ${buttonClass} rounded-1 me-sm-3 me-lg-4`}
                                       to={buttonLink}>
                            {buttonText}
                        </LocalizedLink>
                    </div>
                </div>
            </div>
            <div className="d-none d-md-block text-center mt-n5">
                <svg className="ms-lg-5" width="171" height="97" viewBox="0 0 171 97" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M169.319 54.333C162.404 55.9509 155.712 58.0764 149.09 60.6764L149.07 60.6761C148.967 60.7158 148.863 60.7554 148.76 60.7951C147.3 61.3811 148.325 63.4238 149.672 63.2067C154.548 62.4134 159.994 59.8725 164.87 59.0792C148.278 73.1339 129.684 89.2549 107.779 92.6402C85.6981 96.0539 65.5665 86.7839 56.8768 66.9865C70.9662 55.0671 79.2106 35.6614 79.0299 17.6457C78.9484 10.3157 76.1485 -3.36373 65.7068 1.21851C55.8557 5.53146 52.0466 22.5213 50.5736 31.7739C48.7364 43.2858 49.7593 55.5291 53.8643 66.2014C52.787 67.0812 51.6907 67.8989 50.5755 68.6546C40.6328 75.3851 27.1039 78.8929 16.4487 72.0362C2.91045 63.3259 1.93984 44.9485 1.56902 30.4091C1.54778 29.6265 0.359869 29.6092 0.360624 30.3915C0.322634 44.0809 0.835929 59.065 10.5664 69.6857C18.5722 78.4182 30.4315 79.7753 41.3346 75.9924C46.2437 74.2834 50.7739 71.7557 54.8581 68.6348C59.9738 80.2586 68.9965 89.6956 82.2735 93.7393C113.474 103.223 141.744 83.0494 164.903 63.697L161.901 71.0334C161.267 72.5887 163.76 73.2736 164.393 71.7389C165.986 67.8713 167.569 63.9933 169.152 60.1359C169.288 60.0247 169.695 58.6127 169.821 58.491C170.122 57.1161 169.152 60.1359 169.851 58.4169C170.189 57.6087 170.517 56.79 170.855 55.9818C171.248 54.9994 170.185 54.1192 169.319 54.333ZM54.3624 59.8578C51.4872 49.1623 51.6051 37.5841 54.2025 26.8039C55.5185 21.3369 57.4405 15.8066 60.1572 10.8541C61.2311 8.89354 62.5139 6.77134 64.2307 5.31421C69.4231 0.902277 74.3649 4.80357 75.8002 10.4446C80.5272 28.9489 70.1806 51.6898 55.8431 64.5114C55.2971 63.0109 54.793 61.4698 54.3624 59.8578Z"/>
                </svg>
            </div>
        </div>
    </FullWidthSection>
}

export let HumbleHeaderSection = ({title, subtitle}) => {
    return <section className="position-relative overflow-hidden">
        <div className="container mt-3 mt-md-5 pt-md-3 pt-lg-0 pb-3 pb-md-6 pb-lg-5">
            <div className="row align-items-center">
                <div className="col-12 text-lg-start">
                    <h1 className="mb-3">
                        {title}
                    </h1>
                    <p className="fs-xl mb-4">
                        {subtitle}
                    </p>
                </div>
            </div>
        </div>
    </section>
}

export let CleanHeaderSection = ({className, title, subtitle}) => {
    return <section className={`position-relative pb-5 pb-md-6 pb-lg-7 overflow-hidden ${className}`}>
        <div className="container mt-5 mt-md-7 pt-md-5 pt-lg-0">
            <div className="row align-items-center">
                <div className="col-12 text-lg-start">
                    <h1 className="mb-3 text-center">
                        {title}
                    </h1>
                    <p className="text-center fs-xl mb-4">
                        {subtitle}
                    </p>
                </div>
            </div>
        </div>
    </section>
}
import React from "react";
import {FormattedMessage} from "react-intl";

export let Checkbox = ({
                           validationErrors,
                           containerClassName = '',
                           value,
                           onChange,
                           label,
                           id,
                           disabled,
                           errorField,
                           errorMessage
                       }) => {
    return <>
        <div className={`form-check ${containerClassName}`}>
            <input className="form-check-input"
                   type="checkbox"
                   id={id}
                   checked={value}
                   disabled={disabled}
                   onChange={e => onChange(e.target.checked)}
            />
            <label className={"form-check-label"} htmlFor={id}>
                {label}
            </label>
        </div>
        {validationErrors && <div className={"invalid-feedback fs-xs " + validationErrors.blockClass(errorField)}>
            <FormattedMessage id={errorMessage}/>
        </div>}
    </>
}

export let CheckboxButtons = ({options, selectedValues, disabled, onChange}) => {
    return <>
        <div class="d-flex pdf--hide">
            {options.map((option) => <div class="me-2">
                <input type="checkbox"
                       className={`btn-check`}
                       id={option.value}
                       value={option.value}
                       disabled={disabled}
                       checked={selectedValues.includes(option.value)}
                       onChange={(e) => onChange(option.value, e.target.checked)}/>
                <label htmlFor={option.value} className="btn btn-outline-secondary px-2">
                    <span class="mx-1">{option.label}</span>
                </label>
            </div>)}
        </div>
        <div className={"pdf--d-inline-block"}>
            {selectedValues.join(", ")}
        </div>
    </>
}
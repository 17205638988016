import {copyrightText} from "../../../../../portal-latvia/src/common/component/PublicFooter";
import React from "react";

export let MinimalisticFooter = () => {
    return <footer className="footer py-4">
        <div className="container fs-sm mb-0 py-3">
                    <span className="text-muted me-1">
                      {copyrightText}
                    </span>
        </div>
    </footer>
}
import {useQuery} from "@commons/infra/helpers";
import {PublicLinks} from "../../../utility/links";
import {FormButtonsContainer, FormProceedButton} from "@commons/uinew/component/forms";

let ResultsPage = () => {
    let query = useQuery()

    // let totalQuestions = query.get('tq')
    // let correctAnswers = query.get('ca')
    let prizeId = query.get('pid')
    // let isPityPrize = query.get('pp')
    // let promoCodeValue = query.get('pcv')
    // let promoCodeDiscountPercent = query.get('pcdv')
    let prizeName = decodeURIComponent(query.get('pn'))
    // let prizeUsage = decodeURIComponent(query.get('pu'))

    let getPrizePic = (prizeId) => {
        if (!prizeId || prizeId === 'null') {
            return "medon.png"
        } else {
            return `${prizeId.substring(0, prizeId.indexOf("_"))}.png`
        }
    }

    return <div className={"container pt-5"}>
        <div className={"row"}>

            <div className={"col-xl-6 order-1 py-4"}>
                {/*<h2>{correctAnswers === totalQuestions && <>🥳 Apsveicam, </>}Tu esi atbildējis pareizi*/}
                {/*    uz {correctAnswers}/{totalQuestions} jautājumiem!</h2>*/}
                <h2>Paldies par reģistrāciju!</h2>

                <p>
                    Priecājamies paziņot, ka Tava balva ir:
                </p>
                <p>
                    <strong>{prizeName}</strong>
                </p>

                <p>
                    Pārbaudi savu e-pastu, lai apskatītu detalizētāku informāciju par balvas saņemšanu.
                </p>
                <p>
                    Kamēr esi šeit, iepazīsties ar Medon speciālistiem un tiešsaistes konsultāciju iespējām.
                </p>
                <FormButtonsContainer>
                    <FormProceedButton to={PublicLinks.doctors()} className={"btn btn-primary"}>
                        Apskatīt Medon ārstus
                    </FormProceedButton>

                </FormButtonsContainer>
            </div>

            <div
                className={"offset-xl-1 col-xl-5 order-0 order-xl-2 align-items-center align-content-center justify-content-center px-5 px-lg-0"}>
                <img alt="Dāvana" src={`/img/qagame/prize/${getPrizePic(prizeId)}`}/>
            </div>
        </div>
    </div>
}

export default ResultsPage
export let FullHeightWrapper = ({children, bg = null}) => {
    return <div className={`page-wrapper d-flex flex-column justify-content-between ${bg ? `bg-${bg}` : ''}`}
                style={{flex: '1 0 auto'}}>
        {children}
    </div>
}

export let FullHeightInner = ({children}) => {
    return <div className={"d-flex align-items-center"} style={{flex: '1 0 auto'}}>
        {children}
    </div>
}

import React, {useEffect} from 'react';
import {DoctorRestricted} from "../App";

let FullscreenLayout = ({children}) => {
    useEffect(() => {
        document.querySelector('#root').style.overflowX = 'hidden'
        return () => document.querySelector('#root').style.overflowX = 'auto'
    }, [])

    return <DoctorRestricted root={false}>
        {children}
    </DoctorRestricted>;
}

export default FullscreenLayout;

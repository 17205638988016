let ls = window.localStorage

export let localStorageWrapper = {
    set: (key, value, ttl_millis) => {
        let item = {
            value: value,
            expiry: ttl_millis ? new Date().getTime() + ttl_millis : null,
        }
        ls.setItem(key, JSON.stringify(item))
    },

    get: (key) => {
        let itemStr = ls.getItem(key)

        if (!itemStr) {
            return null
        }

        let item = JSON.parse(itemStr)

        if (item.expiry != null && new Date().getTime() > item.expiry) {
            ls.removeItem(key)
            return null
        }

        return item.value
    },

    remove: (key) => ls.removeItem(key)
}

import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {connect} from "react-redux";

export let PosthogPageviewTracker = () => {
    let location = useLocation();

    useEffect(() => {
        if ('posthog' in window) {
            window.posthog.capture('$pageview')
        }
    }, [location.pathname])

    return <></>
}


let mapStateToProps = (state) => ({
    authentication: state.authentication, soAppointmentRequest: state.soAppointmentRequest
})

export let PosthogSurveyTrigger = ({surveyClassName}) => {
    return <div className={`d-none ${surveyClassName}`}/>
}

export let PosthogOpenFeedbackSurveyTrigger = connect(mapStateToProps)(({authentication, soAppointmentRequest}) => {
    let [show, setShow] = useState(false)
    let [timeoutId, setTimeoutId] = useState()

    useEffect(() => {
        if (authentication.isAuthenticated) {
            return
        }

        let timeoutId = setTimeout(() => {
            setShow(true)
        }, 30_000)
        setTimeoutId(timeoutId)

        return () => clearTimeout(timeoutId)
    }, [setTimeoutId]);

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
    }, [soAppointmentRequest.hasOpenedStep1]);

    return <>
        <div className={`d-none ${show && 'load-posthog-survey--open-feedback'}`}/>
    </>
})

export let PosthogUserIdentifier = connect((state) => ({
    authentication: state.authentication, patient: state.patient, state: state,
}))(({authentication}) => {
    useEffect(() => {
        if (authentication.userId && 'posthog' in window) {
            window.posthog.identify(authentication.userId)
        }
    }, [authentication.userId, window.posthog]);
});
/**
 * @param payload
 * @param files
 * @returns FormData
 */
export let jsonWithFilesRequest = (payload, files) => {
    let formData = new FormData();

    // Append JSON part
    let json = JSON.stringify(payload);
    formData.append('data', new Blob([json], {type: 'application/json'}))

    // Append files part
    files.forEach(file => {
        if (!file.id) {
            formData.append("files", file, file.name)
        }
    })

    return formData
}
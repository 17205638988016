import {FormattedMessage} from "react-intl";

export let VIDEOS = {
    "doctor.maksims-markovs": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'OkhPcnlMBrE',
                title: <FormattedMessage id="video-carousel.youtube.OkhPcnlMBrE"/>
            },
            {
                id: 100,
                youtubeVideoId: 'OkhPcnlMBrE',
                title: <FormattedMessage id="video-carousel.youtube.OkhPcnlMBrE"/>,
                previewVideoUrl: 'https://d.medon.lv/public/videos/doctors/kristina-amosova-01.mp4'
            },
            {
                id: 200,
                youtubeVideoId: 'W2p3qMFtRAk',
                thumbnailYoutubeVideoId: 'zNF1ynliHnY',
                title: <FormattedMessage id="video-carousel.youtube.W2p3qMFtRAk"/>,
                previewVideoUrl: 'https://d.medon.lv/public/videos/doctors/lasma-kalnberza-01.mp4'
            },
            {
                id: 300,
                youtubeVideoId: '0s9EZrfmAUw',
                title: <FormattedMessage id="video-carousel.youtube.0s9EZrfmAUw"/>,
                previewVideoUrl: 'https://d.medon.lv/public/videos/doctors/ainars-rudzitis-01.mp4'
            },
            {
                id: 400,
                youtubeVideoId: 'MZ6qQklQxx8',
                title: <FormattedMessage id="video-carousel.youtube.MZ6qQklQxx8"/>,
                previewVideoUrl: 'https://d.medon.lv/public/videos/doctors/lelde-liepina-01.mp4',
            },
            {
                id: 500,
                youtubeVideoId: 'zNF1ynliHnY',
                title: <FormattedMessage id="video-carousel.youtube.zNF1ynliHnY"/>,
                previewVideoUrl: 'https://d.medon.lv/public/videos/doctors/kristina-amosova-01.mp4'
            },
            {
                id: 600,
                youtubeVideoId: 'dI_HYtIoevo',
                title: <FormattedMessage id="video-carousel.youtube.dI_HYtIoevo"/>,
                previewVideoUrl: 'https://d.medon.lv/public/videos/doctors/lasma-kalnberza-01.mp4'
            },
        ]
    },
    "doctor.ainars-rudzitis": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'OkhPcnlMBrE',
                title: <FormattedMessage id="video-carousel.youtube.OkhPcnlMBrE"/>
            },
            {
                id: 200,
                youtubeVideoId: 'W2p3qMFtRAk',
                thumbnailYoutubeVideoId: 'zNF1ynliHnY',
                title: <FormattedMessage id="video-carousel.youtube.W2p3qMFtRAk"/>
            },
            {
                id: 300,
                youtubeVideoId: '0s9EZrfmAUw',
                title: <FormattedMessage id="video-carousel.youtube.0s9EZrfmAUw"/>
            },
            {
                id: 400,
                youtubeVideoId: 'MZ6qQklQxx8',
                title: <FormattedMessage id="video-carousel.youtube.MZ6qQklQxx8"/>
            },
            {
                id: 500,
                youtubeVideoId: 'zNF1ynliHnY',
                title: <FormattedMessage id="video-carousel.youtube.zNF1ynliHnY"/>
            },
            {
                id: 600,
                youtubeVideoId: 'dI_HYtIoevo',
                title: <FormattedMessage id="video-carousel.youtube.dI_HYtIoevo"/>
            },
        ]
    },
    "doctor.darta-geizane": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'VaUiRer46WM',
                title: <FormattedMessage id="video-carousel.youtube.VaUiRer46WM"/>
            },
            {
                id: 200,
                youtubeVideoId: '37GpK0jej9Q',
                title: <FormattedMessage id="video-carousel.youtube.37GpK0jej9Q"/>
            },
            {
                id: 300,
                youtubeVideoId: 'Of4bodvwThc',
                title: <FormattedMessage id="video-carousel.youtube.Of4bodvwThc"/>
            },
            {
                id: 400,
                youtubeVideoId: 'wz59zDFBw6I',
                title: <FormattedMessage id="video-carousel.youtube.wz59zDFBw6I"/>
            },
            {
                id: 500,
                youtubeVideoId: 'YZFNSvrtrng',
                title: <FormattedMessage id="video-carousel.youtube.YZFNSvrtrng"/>
            },
        ]
    },
    "doctor.kristina-amosova": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'LS8Uc9IiRnI',
                title: <FormattedMessage id="video-carousel.youtube.LS8Uc9IiRnI"/>
            },
            {
                id: 200,
                youtubeVideoId: 'TTOgJVgnHwU',
                title: <FormattedMessage id="video-carousel.youtube.TTOgJVgnHwU"/>
            },
            {
                id: 300,
                youtubeVideoId: 'Fe82aRzpnpk',
                title: <FormattedMessage id="video-carousel.youtube.Fe82aRzpnpk"/>
            },
            {
                id: 400,
                youtubeVideoId: 'IOrDpYK5Dw4',
                title: <FormattedMessage id="video-carousel.youtube.IOrDpYK5Dw4"/>
            },
        ]
    },
    "doctor.linda-brokane": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'NrtCDxiy-m0',
                title: <FormattedMessage id="video-carousel.youtube.NrtCDxiy-m0"/>
            },
            {
                id: 200,
                youtubeVideoId: 'B1g2I15qAeE',
                title: <FormattedMessage id="video-carousel.youtube.B1g2I15qAeE"/>
            },
            {
                id: 300,
                youtubeVideoId: 'v7Bh55lF4A4',
                title: <FormattedMessage id="video-carousel.youtube.v7Bh55lF4A4"/>
            },
            {
                id: 400,
                youtubeVideoId: 'O7pG6Vw93HA',
                title: <FormattedMessage id="video-carousel.youtube.O7pG6Vw93HA"/>
            },
            {
                id: 500,
                youtubeVideoId: 'lzQqupYg_as',
                title: <FormattedMessage id="video-carousel.youtube.lzQqupYg_as"/>
            },
            {
                id: 600,
                youtubeVideoId: 'GoNV7Sv_SU0',
                title: <FormattedMessage id="video-carousel.youtube.GoNV7Sv_SU0"/>
            },
            {
                id: 700,
                youtubeVideoId: '-0iyXs_WhbM',
                title: <FormattedMessage id="video-carousel.youtube.-0iyXs_WhbM"/>
            },
        ]
    },
    "doctor.lelde-liepina": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'i0WZvjHyoAg',
                title: <FormattedMessage id="video-carousel.youtube.i0WZvjHyoAg"/>
            },
            {
                id: 200,
                youtubeVideoId: 'Xasa8b4sbsY',
                title: <FormattedMessage id="video-carousel.youtube.Xasa8b4sbsY"/>
            },
            {
                id: 300,
                youtubeVideoId: '0qCUkub3u9o',
                title: <FormattedMessage id="video-carousel.youtube.0qCUkub3u9o"/>
            }
        ]
    },
    "doctor.lasma-kalnberza": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'mYV5M4kR3QA',
                title: <FormattedMessage id="video-carousel.youtube.mYV5M4kR3QA"/>
            },
            {
                id: 200,
                youtubeVideoId: 'pAoE9iYOphw',
                title: <FormattedMessage id="video-carousel.youtube.pAoE9iYOphw"/>
            },
            {
                id: 300,
                youtubeVideoId: 'zmizgF9btLI',
                title: <FormattedMessage id="video-carousel.youtube.zmizgF9btLI"/>
            },
            {
                id: 400,
                youtubeVideoId: 'RIDVnkwees4',
                title: <FormattedMessage id="video-carousel.youtube.RIDVnkwees4"/>
            },
            {
                id: 500,
                youtubeVideoId: 'hUdcJrYMuEM',
                title: <FormattedMessage id="video-carousel.youtube.hUdcJrYMuEM"/>
            },
            {
                id: 600,
                youtubeVideoId: 'hkJcovJ2RWE',
                title: <FormattedMessage id="video-carousel.youtube.hkJcovJ2RWE"/>
            },
        ]
    },
    "doctor.eva-smite": {
        videos: [
            {
                id: 100,
                youtubeVideoId: '4zm_WcU2fJw',
                title: <FormattedMessage id="video-carousel.youtube.4zm_WcU2fJw"/>
            },
            {
                id: 200,
                youtubeVideoId: 'X2YJeGWkMjA',
                title: <FormattedMessage id="video-carousel.youtube.X2YJeGWkMjA"/>
            },
            {
                id: 300,
                youtubeVideoId: 'YpJ7lCrvmVI',
                title: <FormattedMessage id="video-carousel.youtube.YpJ7lCrvmVI"/>
            },
            {
                id: 400,
                youtubeVideoId: 'xXW9NWABl9o',
                title: <FormattedMessage id="video-carousel.youtube.xXW9NWABl9o"/>
            },
            {
                id: 500,
                youtubeVideoId: 'C23Fs-dYNgI',
                title: <FormattedMessage id="video-carousel.youtube.C23Fs-dYNgI"/>
            },
            {
                id: 600,
                youtubeVideoId: '9gw-nqqExfo',
                title: <FormattedMessage id="video-carousel.youtube.9gw-nqqExfo"/>
            },
            {
                id: 700,
                youtubeVideoId: 'RQ-dYPkBpc8',
                title: <FormattedMessage id="video-carousel.youtube.RQ-dYPkBpc8"/>
            },
        ]
    },
    "doctor.natalia-pozhilenkova": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'ex6T11YDmjU',
                title: <FormattedMessage id="video-carousel.youtube.ex6T11YDmjU"/>
            },
            {
                id: 200,
                youtubeVideoId: 'M_Cy-JAaUf8',
                title: <FormattedMessage id="video-carousel.youtube.M_Cy-JAaUf8"/>
            },
            {
                id: 300,
                youtubeVideoId: 'Wpm2uHQTelU',
                title: <FormattedMessage id="video-carousel.youtube.Wpm2uHQTelU"/>
            },
            {
                id: 400,
                youtubeVideoId: 'xbyQoZ541rk',
                title: <FormattedMessage id="video-carousel.youtube.xbyQoZ541rk"/>
            },
            {
                id: 500,
                youtubeVideoId: 'fUfmM81QBzI',
                title: <FormattedMessage id="video-carousel.youtube.fUfmM81QBzI"/>
            },
            {
                id: 600,
                youtubeVideoId: 'CGxg7ZdTueE',
                title: <FormattedMessage id="video-carousel.youtube.CGxg7ZdTueE"/>
            },
        ]
    },

    "speciality.family-physician": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'YZFNSvrtrng',
                title: <FormattedMessage id="video-carousel.youtube.YZFNSvrtrng"/>
            },
            {
                id: 200,
                youtubeVideoId: 'wz59zDFBw6I',
                title: <FormattedMessage id="video-carousel.youtube.wz59zDFBw6I"/>
            },
            {
                id: 300,
                youtubeVideoId: 'Of4bodvwThc',
                title: <FormattedMessage id="video-carousel.youtube.Of4bodvwThc"/>
            },
            {
                id: 400,
                youtubeVideoId: '37GpK0jej9Q',
                title: <FormattedMessage id="video-carousel.youtube.37GpK0jej9Q"/>
            },
            {
                id: 500,
                youtubeVideoId: 'VaUiRer46WM',
                title: <FormattedMessage id="video-carousel.youtube.VaUiRer46WM"/>
            }
        ]
    },
    "speciality.cardiologist": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'dI_HYtIoevo',
                title: <FormattedMessage id="video-carousel.youtube.dI_HYtIoevo"/>
            },
            {
                id: 200,
                youtubeVideoId: 'zNF1ynliHnY',
                title: <FormattedMessage id="video-carousel.youtube.zNF1ynliHnY"/>
            },
            {
                id: 300,
                youtubeVideoId: 'MZ6qQklQxx8',
                title: <FormattedMessage id="video-carousel.youtube.MZ6qQklQxx8"/>
            },
            {
                id: 400,
                youtubeVideoId: '0s9EZrfmAUw',
                title: <FormattedMessage id="video-carousel.youtube.0s9EZrfmAUw"/>
            },
            {
                id: 500,
                youtubeVideoId: 'W2p3qMFtRAk',
                thumbnailyoutubeVideoId: 'zNF1ynliHnY',
                title: <FormattedMessage id="video-carousel.youtube.zNF1ynliHnY"/>
            },
            {
                id: 600,
                youtubeVideoId: 'OkhPcnlMBrE',
                title: <FormattedMessage id="video-carousel.youtube.OkhPcnlMBrE"/>
            },
        ]
    },
    "speciality.oncologist": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'NrtCDxiy-m0',
                title: <FormattedMessage id="video-carousel.youtube.NrtCDxiy-m0"/>
            },
            {
                id: 200,
                youtubeVideoId: 'B1g2I15qAeE',
                title: <FormattedMessage id="video-carousel.youtube.B1g2I15qAeE"/>
            },
            {
                id: 300,
                youtubeVideoId: 'v7Bh55lF4A4',
                title: <FormattedMessage id="video-carousel.youtube.v7Bh55lF4A4"/>
            },
            {
                id: 400,
                youtubeVideoId: 'O7pG6Vw93HA',
                title: <FormattedMessage id="video-carousel.youtube.O7pG6Vw93HA"/>
            },
            {
                id: 500,
                youtubeVideoId: 'lzQqupYg_as',
                title: <FormattedMessage id="video-carousel.youtube.lzQqupYg_as"/>
            },
            {
                id: 600,
                youtubeVideoId: 'GoNV7Sv_SU0',
                title: <FormattedMessage id="video-carousel.youtube.GoNV7Sv_SU0"/>
            },
            {
                id: 700,
                youtubeVideoId: '-0iyXs_WhbM',
                title: <FormattedMessage id="video-carousel.youtube.-0iyXs_WhbM"/>
            },
        ]
    },
    "speciality.pediatrician": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'i0WZvjHyoAg',
                title: <FormattedMessage id="video-carousel.youtube.i0WZvjHyoAg"/>
            },
            {
                id: 200,
                youtubeVideoId: 'Xasa8b4sbsY',
                title: <FormattedMessage id="video-carousel.youtube.Xasa8b4sbsY"/>
            },
            {
                id: 300,
                youtubeVideoId: 'Fe82aRzpnpk',
                title: <FormattedMessage id="video-carousel.youtube.Fe82aRzpnpk"/>
            },
            {
                id: 400,
                youtubeVideoId: 'IOrDpYK5Dw4',
                title: <FormattedMessage id="video-carousel.youtube.IOrDpYK5Dw4"/>
            },
            {
                id: 100,
                youtubeVideoId: 'LS8Uc9IiRnI',
                title: <FormattedMessage id="video-carousel.youtube.LS8Uc9IiRnI"/>
            },
            {
                id: 200,
                youtubeVideoId: 'TTOgJVgnHwU',
                title: <FormattedMessage id="video-carousel.youtube.TTOgJVgnHwU"/>
            },
        ]
    },
    "speciality.dermatologist": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'mYV5M4kR3QA',
                title: <FormattedMessage id="video-carousel.youtube.mYV5M4kR3QA"/>
            },
            {
                id: 200,
                youtubeVideoId: 'pAoE9iYOphw',
                title: <FormattedMessage id="video-carousel.youtube.pAoE9iYOphw"/>
            },
            {
                id: 300,
                youtubeVideoId: 'zmizgF9btLI',
                title: <FormattedMessage id="video-carousel.youtube.zmizgF9btLI"/>
            },
            {
                id: 400,
                youtubeVideoId: 'RIDVnkwees4',
                title: <FormattedMessage id="video-carousel.youtube.RIDVnkwees4"/>
            },
            {
                id: 500,
                youtubeVideoId: 'hUdcJrYMuEM',
                title: <FormattedMessage id="video-carousel.youtube.hUdcJrYMuEM"/>
            },
            {
                id: 600,
                youtubeVideoId: 'hkJcovJ2RWE',
                title: <FormattedMessage id="video-carousel.youtube.hkJcovJ2RWE"/>
            },
        ]
    },
    "speciality.nutritionist": {
        videos: [
            {
                id: 100,
                youtubeVideoId: '4zm_WcU2fJw',
                title: <FormattedMessage id="video-carousel.youtube.4zm_WcU2fJw"/>
            },
            {
                id: 200,
                youtubeVideoId: 'X2YJeGWkMjA',
                title: <FormattedMessage id="video-carousel.youtube.X2YJeGWkMjA"/>
            },
            {
                id: 300,
                youtubeVideoId: 'YpJ7lCrvmVI',
                title: <FormattedMessage id="video-carousel.youtube.YpJ7lCrvmVI"/>
            },
            {
                id: 400,
                youtubeVideoId: 'xXW9NWABl9o',
                title: <FormattedMessage id="video-carousel.youtube.xXW9NWABl9o"/>
            },
            {
                id: 500,
                youtubeVideoId: 'C23Fs-dYNgI',
                title: <FormattedMessage id="video-carousel.youtube.C23Fs-dYNgI"/>
            },
            {
                id: 600,
                youtubeVideoId: '9gw-nqqExfo',
                title: <FormattedMessage id="video-carousel.youtube.9gw-nqqExfo"/>
            },
            {
                id: 700,
                youtubeVideoId: 'RQ-dYPkBpc8',
                title: <FormattedMessage id="video-carousel.youtube.RQ-dYPkBpc8"/>
            },
        ]
    },
    "speciality.gynecologist": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'ex6T11YDmjU',
                title: <FormattedMessage id="video-carousel.youtube.ex6T11YDmjU"/>
            },
            {
                id: 200,
                youtubeVideoId: 'M_Cy-JAaUf8',
                title: <FormattedMessage id="video-carousel.youtube.M_Cy-JAaUf8"/>
            },
            {
                id: 300,
                youtubeVideoId: 'Wpm2uHQTelU',
                title: <FormattedMessage id="video-carousel.youtube.Wpm2uHQTelU"/>
            },
            {
                id: 400,
                youtubeVideoId: 'xbyQoZ541rk',
                title: <FormattedMessage id="video-carousel.youtube.xbyQoZ541rk"/>
            },
            {
                id: 500,
                youtubeVideoId: 'fUfmM81QBzI',
                title: <FormattedMessage id="video-carousel.youtube.fUfmM81QBzI"/>
            },
            {
                id: 600,
                youtubeVideoId: 'CGxg7ZdTueE',
                title: <FormattedMessage id="video-carousel.youtube.CGxg7ZdTueE"/>
            },
        ]
    },

    "health-issue.kardiovaskulara-sistema": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'OkhPcnlMBrE',
                title: <FormattedMessage id="video-carousel.youtube.OkhPcnlMBrE"/>
            },
            {
                id: 200,
                youtubeVideoId: 'W2p3qMFtRAk',
                thumbnailyoutubeVideoId: 'zNF1ynliHnY',
                title: <FormattedMessage id="video-carousel.youtube.zNF1ynliHnY"/>
            },
            {
                id: 300,
                youtubeVideoId: '0s9EZrfmAUw',
                title: <FormattedMessage id="video-carousel.youtube.0s9EZrfmAUw"/>
            },
            {
                id: 400,
                youtubeVideoId: 'MZ6qQklQxx8',
                title: <FormattedMessage id="video-carousel.youtube.MZ6qQklQxx8"/>
            },
            {
                id: 500,
                youtubeVideoId: 'zNF1ynliHnY',
                title: <FormattedMessage id="video-carousel.youtube.zNF1ynliHnY"/>
            },
            {
                id: 600,
                youtubeVideoId: 'dI_HYtIoevo',
                title: <FormattedMessage id="video-carousel.youtube.dI_HYtIoevo"/>
            },
        ]
    },
    "health-issue.alergijas-un-nepanesibas": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'LS8Uc9IiRnI',
                title: <FormattedMessage id="video-carousel.youtube.LS8Uc9IiRnI"/>
            },
            {
                id: 200,
                youtubeVideoId: 'TTOgJVgnHwU',
                title: <FormattedMessage id="video-carousel.youtube.TTOgJVgnHwU"/>
            },
            {
                id: 300,
                youtubeVideoId: 'IOrDpYK5Dw4',
                title: <FormattedMessage id="video-carousel.youtube.IOrDpYK5Dw4"/>
            },
        ]
    },
    "health-issue.neirologiskas-slimibas": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'i0WZvjHyoAg',
                title: <FormattedMessage id="video-carousel.youtube.i0WZvjHyoAg"/>
            },
            {
                id: 200,
                youtubeVideoId: '0qCUkub3u9o',
                title: <FormattedMessage id="video-carousel.youtube.0qCUkub3u9o"/>
            },
            {
                id: 300,
                youtubeVideoId: 'Xasa8b4sbsY',
                title: <FormattedMessage id="video-carousel.youtube.Xasa8b4sbsY"/>
            },
        ]
    },
    "health-issue.vezis": {
        videos: [
            {
                id: 100,
                youtubeVideoId: 'NrtCDxiy-m0',
                title: <FormattedMessage id="video-carousel.youtube.NrtCDxiy-m0"/>
            },
            {
                id: 200,
                youtubeVideoId: 'B1g2I15qAeE',
                title: <FormattedMessage id="video-carousel.youtube.B1g2I15qAeE"/>
            },
            {
                id: 300,
                youtubeVideoId: 'v7Bh55lF4A4',
                title: <FormattedMessage id="video-carousel.youtube.v7Bh55lF4A4"/>
            },
            {
                id: 400,
                youtubeVideoId: 'O7pG6Vw93HA',
                title: <FormattedMessage id="video-carousel.youtube.O7pG6Vw93HA"/>
            },
            {
                id: 500,
                youtubeVideoId: 'lzQqupYg_as',
                title: <FormattedMessage id="video-carousel.youtube.lzQqupYg_as"/>
            },
            {
                id: 600,
                youtubeVideoId: 'GoNV7Sv_SU0',
                title: <FormattedMessage id="video-carousel.youtube.GoNV7Sv_SU0"/>
            },
            {
                id: 700,
                youtubeVideoId: '-0iyXs_WhbM',
                title: <FormattedMessage id="video-carousel.youtube.-0iyXs_WhbM"/>
            },
        ]
    }
}
import axios from "@commons/infra/http/axios/axios";

export const AppointmentsApi = {
    // TODO it's public now
    getAppointment: (id) => axios.get(`/api/appointments/${id}`),

    getBooking: (id) => axios.get(`/api/bookings/${id}`),

    authenticateWithSwedbankBanklink: (id) => axios.post(`/api/patient/booking/${id}/authentication/swedbank`),

    authenticateWithSmartId: (personalCode, id, country) => axios.post(`/api/patient/booking/${id}/authentication/smart-id`, {
        personalCode,
        country
    }),
};

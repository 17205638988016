import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import React from "react";
import {PublicLinks} from "../../utility/links";

export let faqItems = {
    aboutMedon: {
        whatIsMedon: {
            hash: 'about-medon-q1',
            titleMessage: {id: 'faq.about-medon-q1.title'},
            textMessage: {id: 'faq.about-medon-q1.text'}
        },
        howDoesMedonWork: {
            hash: 'about-medon-q2',
            titleMessage: {id: 'faq.about-medon-q2.title'},
            textMessage: {id: 'faq.about-medon-q2.text'}
        },
        isMedonCountryOrRegionSpecific: {
            hash: 'about-medon-q3',
            titleMessage: {id: 'faq.about-medon-q3.title'},
            textMessage: {id: 'faq.about-medon-q3.text'}
        },
        areThereAgeRestrictions: {
            hash: 'about-medon-q4',
            titleMessage: {id: 'faq.about-medon-q4.title'},
            textMessage: {id: 'faq.about-medon-q4.text'}
        },
        isMyPersonalInformationProtected: {
            hash: 'about-medon-q5',
            titleMessage: {id: 'faq.about-medon-q5.title'},
            textMessage: {id: 'faq.about-medon-q5.text'}
        },
        whatSpecialistsDoesMedonWorkWith: {
            hash: 'about-medon-q6',
            titleMessage: {id: 'faq.about-medon-q6.title'},
            textMessage: {id: 'faq.about-medon-q6.text'}
        }
    },
    costs: {
        whatIsThePrice: {
            hash: 'costs-q1',
            titleMessage: {id: 'faq.costs-q1.title'},
            textMessage: {
                id: 'faq.costs-q1.text',
                values: {
                    link: chunk => <LocalizedLink to={PublicLinks.doctors()}>{chunk}</LocalizedLink>
                }
            }
        },
        whereIsInvoice: {
            hash: 'costs-q3', titleMessage: {id: 'faq.costs-q3.title'}, textMessage: {
                id: 'faq.costs-q3.text', values: {
                    link: chunk => <LocalizedLink to={PublicLinks.doctors()}>{chunk}</LocalizedLink>
                }
            }
        },
        whenDoIHaveToPay: {
            hash: 'costs-q4', titleMessage: {id: 'faq.costs-q4.title'}, textMessage: {
                id: 'faq.costs-q4.text', values: {
                    link: chunk => <LocalizedLink to={PublicLinks.doctors()}>{chunk}</LocalizedLink>
                }
            }
        },
        canIGetRecipesOnline: {
            hash: 'costs-q5', titleMessage: {id: 'faq.costs-q5.title'}, textMessage: {
                id: 'faq.costs-q5.text', values: {
                    link: chunk => <LocalizedLink to={PublicLinks.doctors()}>{chunk}</LocalizedLink>,
                    br: <br/>
                }
            }
        },
        canICancel: {
            hash: 'costs-q6', titleMessage: {id: 'faq.costs-q6.title'}, textMessage: {
                id: 'faq.costs-q6.text', values: {
                    link: chunk => <LocalizedLink to={PublicLinks.doctors()}>{chunk}</LocalizedLink>,
                    br: <br/>
                }
            }
        }
    },
    remoteConsultations: {
        whatConsultationCanBeProvided: {
            hash: 'remote-appointment-q1', titleMessage: {id: 'faq.remote-appointment-q1.title'}, textMessage: {
                id: 'faq.remote-appointment-q1.text', values: {
                    link: chunk => <LocalizedLink to={PublicLinks.doctors()}>{chunk}</LocalizedLink>
                }
            }
        },
        howDoIGetInTouch: {
            hash: 'remote-appointment-q2',
            titleMessage: {id: 'faq.remote-appointment-q2.title'},
            textMessage: {id: 'faq.remote-appointment-q2.text'}
        },
        whatTechnicalSolutionIsRequired: {
            hash: 'remote-appointment-q3',
            titleMessage: {id: 'faq.remote-appointment-q3.title'},
            textMessage: {id: 'faq.remote-appointment-q3.text'}
        },
        howToPrepare: {
            hash: 'remote-appointment-q4',
            titleMessage: {id: 'faq.remote-appointment-q4.title'},
            textMessage: {id: 'faq.remote-appointment-q4.text'}
        }
    },
}

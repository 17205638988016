import React, {useEffect, useState} from 'react';
import {useQuery} from "@commons/infra/helpers";
import {useLocation, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {localStorageWrapper} from "@commons/infra/localstorage";

let CUSTOM_REDIRECT_PATH_PARAM = 'rp';

/**
 * @return string|null
 */
let getCustomRedirectPathFromURL = (query) => query.get(CUSTOM_REDIRECT_PATH_PARAM);

export let useRedirectPath = () => {
    let {pathname, search} = useLocation()
    let query = useQuery()

    let getFromQuery = () => query.get(CUSTOM_REDIRECT_PATH_PARAM)
    let forget = () => localStorageWrapper.remove('rp')

    return {
        rememberIfPassed: () => {
            let redirectPath = getFromQuery()

            if (redirectPath && redirectPath !== 'null') { // TODO
                console.log('redirect_path - remembering ', redirectPath, pathname, search);
                localStorageWrapper.set('rp', redirectPath)
            }
        },

        getAndForget: () => {
            let path = localStorageWrapper.get('rp') || getFromQuery()
            forget()
            console.log('redirect_path - getting ', path, pathname, search);
            return path
        },

        isSet: () => {
            return localStorageWrapper.get('rp') !== null && !getFromQuery()
        },

        forget: () => {
            localStorageWrapper.remove('rp')
        }
    }
}

let resolveCustomRedirectPath = (query) => {
    let fromLocalStorage = localStorageWrapper.get(CUSTOM_REDIRECT_PATH_PARAM);
    let fromPath = getCustomRedirectPathFromURL(query);

    return fromPath || fromLocalStorage
}

/**
 * Saves post-login path into localstorage
 */
export let CustomRedirectSaver = () => {
    let [customRedirectPath] = useState(getCustomRedirectPathFromURL(useQuery()));
    useEffect(() => {
        if (!customRedirectPath) return
        localStorageWrapper.set(CUSTOM_REDIRECT_PATH_PARAM, customRedirectPath, 1000 * 60 * 60)
    }, [customRedirectPath])

    return <></>
}

const mapStateToProps = (state) => ({
    authentication: state.authentication
})

/**
 * Redirects client to post-login path if it is provided
 * Post-login path resolution chain:
 * - localstorage
 * - query parameter
 */
export let PostLoginRedirectHandler = connect(mapStateToProps)(({authentication, redirectResolver}) => {
    let rp = useRedirectPath()
    let navigate = useNavigate()

    let [redirectPath, setRedirectPath] = useState()

    useEffect(() => {
        if (authentication.isAuthenticated) {
            setRedirectPath(redirectResolver(authentication.authorities, rp.getAndForget()))
        }
    }, [authentication])

    useEffect(() => {
        if (!redirectPath)
            return

        if (redirectPath.external) {
            window.location = redirectPath.to
            return
        }

        console.log('redirecting to ', redirectPath.to);
        navigate(redirectPath.to, {replace: true})
    }, [redirectPath, navigate])

    return <></>
})

let initialState = {
    show: true
};

export let HIDE_STICKY_TOP_BANNER = 'HIDE_STICKY_TOP_BANNER';
export let hideStickyTopBanner = () => ({type: HIDE_STICKY_TOP_BANNER});

export default function stickyBannerReducer(state = initialState, action) {
    switch (action.type) {
        case HIDE_STICKY_TOP_BANNER:
            return {
                ...state,
                show: false
            }
        default:
            return state
    }
}

import {connect} from "react-redux";

let PosthogSurveyTrigger = ({surveyId}) => {
    return surveyId ? <span className={`load-posthog-survey--${surveyId}`}/> : <></>;
}

const mapStateToProps = (state) => ({
    surveyId: state.posthog.surveyIdToShow
});

export default connect(mapStateToProps)(PosthogSurveyTrigger)

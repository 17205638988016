import {QuoteSection} from "@commons/uinew/domainsection/QuoteSection";
import {RichText} from "@commons/infra/storyblok/richText";
import {SectionWrapper} from "@commons/infra/storyblok/components/SectionWrapper";

export let QuoteSectionStoryblokWrapper = ({blok}) => {
    return <SectionWrapper differentColor={blok.IsDifferentColorSection}>
        <QuoteSection
            name={blok.Name}
            position={blok.Position}
            text={<RichText content={blok.Text}/>}
            imgSrc={blok.ThumbnailUrl}
        />
    </SectionWrapper>
}
import {H2} from "@commons/uinew/text/headings";
import {FormattedMessage} from "react-intl";
import {ContentColumn, ContentColumnsWrapper} from "@commons/uinew/domaincomponent/contentColumns";
import React from "react";

export let BenefitSectionV1 = ({items, title = <FormattedMessage id="so_pages.home.300"/>}) => {
    return <>
        <H2 bottomPadding={'lg'}>
            {title}
        </H2>
        <ContentColumnsWrapper colsSm={Object.values(items).length}>
            {items.map(i => <ContentColumn
                aiIcon={i.aiIcon}
                heading={i.heading}
                text={i.text}
                linkTo={i.linkTo}
                linkText={i.linkText}
            />)}
        </ContentColumnsWrapper>
    </>;
}
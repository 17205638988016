import {H2} from "@commons/uinew/text/headings";
import {HorizontalItemNumber} from "@commons/ui/component/HorizontalItem";
import {SecretlyNumbered} from "@commons/ui/component/SecretlyNumbered";
import {DifferentBackgroundSection} from "@commons/uinew/section/DifferentBackgroundSection";
import React from "react";
import {RichText} from "@commons/infra/storyblok/richText";

export let Steps = ({blok}) => {
    return <DifferentBackgroundSection>
        <H2 bottomPadding={'xl'}>
            {blok.Title}
        </H2>
        <div className={"row"}>
            {blok.Items.map((item, idx) => <HorizontalItemNumber
                number={idx + 1}
                title={<SecretlyNumbered number={idx + 1}>
                    {item.Title}
                </SecretlyNumbered>}
                description={<RichText content={item.Text}/>}
                colWidth={12 / blok.Items.length}/>)}
        </div>
    </DifferentBackgroundSection>
}
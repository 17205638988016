import {LANGUAGE_CODE_ENGLISH, LANGUAGE_CODE_LATVIAN, LANGUAGE_CODE_RUSSIAN} from "@commons/infra/i18n/LocalizedRouter";

export let defaultAppConfig = {
    clinic: {
        address: {
            short: "Gardenes ielā 13, Rīgā",
            full: "Gardenes iela 13, Zemgales priekšpilsēta, Rīga, medicīnas centra \"Nucleo\" telpās",
            googleMapsLink: "https://www.google.com/maps/place/Gardenes+iela+13,+Zemgales+priek%C5%A1pils%C4%93ta,+R%C4%ABga,+LV-1002/@56.9276146,24.0697844,17z/data=!4m6!3m5!1s0x46eed038a8e17307:0xcc847f6bb92a270b!8m2!3d56.9274365!4d24.0718246!16s%2Fg%2F11gwm6yny_?entry=ttu"
        }
    },
    i18n: {
        languages: [LANGUAGE_CODE_LATVIAN, LANGUAGE_CODE_RUSSIAN, LANGUAGE_CODE_ENGLISH],
        defaultLanguage: LANGUAGE_CODE_LATVIAN,
    },
    patient: { // duplicates in env config
        appointment: {
            showTimeToStartCallBefore: 60 // minutes
        }
    },
}
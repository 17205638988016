import {PublicationsSwiperSection} from "@commons/uinew/domainsection/swipers/PublicationsSwiperSection";
import React from "react";

export let LatvianPublicationsSwiperSection = () => {
    return <PublicationsSwiperSection
        items={[
            {
                src: "https://www.tv3.lv/reklamraksti/attalinato-konsultaciju-ieguvumi/",
                imgSrc: "/img/home-v2/publications/tv3.png",
                className: "px-lg-6"
            },
            {
                src: "https://www.delfi.lv/life/56017206/veseliba/55184682/sievietes-sirds-veselibas-dienas-februari-pieejamas-bezmaksas-arstu-konsultacijas",
                imgSrc: "/img/home-v2/publications/delfi.lv.svg",
                className: "px-lg-6"
            },
            {
                src: "https://jauns.lv/raksts/par-veselibu/544222-par-sirdi-diemzel-sak-domat-tikai-tad[…]zibas-sirds-kirurgs-martins-erglis-atklati-par-dzivi-un-veselibu",
                imgSrc: "/img/home-v2/publications/jauns.lv.png",
            },
            {
                src: "https://www.la.lv/sirds-slimibu-riska-kalkulators",
                imgSrc: "/img/home-v2/publications/la.lv.png",
                className: "px-lg-6"
            },
            {
                src: "https://www.lsm.lv/raksts/dzive--stils/veseliba/10.05.2023-sirds-mazspejas-menesi-piedavas-bezmaksas-arstu-konsultacijas-tiessaiste.a508030/",
                imgSrc: "/img/home-v2/publications/lsm.lv.png",
            },
        ]}/>
}
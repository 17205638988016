import axios from "./http/axios/axios";

export const transformAuthorities = (input) => input.map(a => a.authority);

const service = {
    login: function (email, password) {
        const params = new URLSearchParams();
        params.append('username', email);
        params.append('password', password);

        return axios.post('/api/login', params)
            .then((res) => ({success: !res.request.responseURL.endsWith('/login?error')}))
            .catch((e) => {
                console.error(e);
            });
    },

    getIdentity: function () {
        return axios.get('/api/identity')
            .then(res => {
                    if (!res || !res.data || !res.data.isAuthenticated) {
                        return {isAuthenticated: false}
                    } else {
                        return {
                            ...res.data,
                            isAuthenticated: true,
                            authorities: transformAuthorities(res.data.authorities)
                        }
                    }
                }
            )
    },

    logout: function () {
        return axios.post('/api/logout')
    },

    stopImpersonating: function () {
        return axios.post('/api/stop-impersonating')
    }
}

export default service;

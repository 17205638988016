import {createBreakpoints, defaultSwiperSettings} from "@commons/uinew/component/swiper";
import React from "react";
import LazySwiper from "@commons/uinew/component/swiper/LazySwiper";

export let PublicationsSwiperSection = ({items, breakpoints = createBreakpoints(1, 3, 5)}) => {
    return <div className={"row justify-content-center"}>
        <LazySwiper
            {...defaultSwiperSettings({
                className: "mb-lg-n5",
                wrapperClass: "align-items-center pb-0"
            })}
            autoHeight={false}
            breakpoints={breakpoints}
            slideClassName={"align-content-center"}
            items={items.map(i => <PublicationItem
                src={i.src}
                imgSrc={i.imgSrc}
                imgAlt={i.imgAlt}
                className={i.className}
                style={i.style}/>
            )}
        />
    </div>
}

let PublicationItem = ({imgSrc, imgAlt, src, className, style}) => {
    return <div className={`d-flex align-items-center justify-content-center px-l-g2 py-lg-3 ${className || ''}`}
                style={style}>
        <a className={"hide-external-icon"} href={src} target={"_black"} rel="noopener noreferrer">
            <img alt={imgAlt} src={imgSrc} style={{width: '100%'}}/>
        </a>
    </div>
}
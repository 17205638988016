export let TRY_LOGIN = 'TRY_LOGIN';
export let TRY_LOGIN_FAILED = 'TRY_LOGIN_FAILED';
export let LOGIN_ASYNC = 'LOGIN_ASYNC';
export let LOGIN = 'LOGIN';
export let LOGOUT = 'LOGOUT';
export let TRY_STOP_IMPERSONATING = 'TRY_STOP_IMPERSONATING';
export let TRY_LOGOUT = 'TRY_LOGOUT';
export let TRY_LOGOUT_FAILED = 'TRY_LOGOUT_FAILED';
export let FETCH_PATIENT_PROFILE_ASYNC = 'FETCH_PATIENT_PROFILE_ASYNC';
export let FETCH_SO_PATIENT_PROFILE_ASYNC = 'FETCH_SO_PATIENT_PROFILE_ASYNC';
export let FETCHED_PATIENT_PROFILE = 'FETCHED_PATIENT_PROFILE';
export let FETCH_ONGOING_RESERVATIONS_ASYNC = 'FETCH_ONGOING_RESERVATIONS_ASYNC';
export let CLEAR_ONGOING_RESERVATIONS = 'CLEAR_ONGOING_RESERVATIONS';
export let FETCHED_ONGOING_RESERVATIONS = 'FETCHED_ONGOING_RESERVATIONS';
export let FETCH_DOCTOR_PROFILE_ASYNC = 'FETCH_DOCTOR_PROFILE_ASYNC';
export let FETCHED_DOCTOR_PROFILE = 'FETCHED_DOCTOR_PROFILE';
export let FETCH_DOCTOR_PROFILE_FAILED = 'FETCH_DOCTOR_PROFILE_FAILED';
export let FETCH_DOCTOR_MONTHLY_OVERVIEW_ASYNC = 'FETCH_DOCTOR_MONTHLY_OVERVIEW_ASYNC';
export let FETCHED_DOCTOR_MONTHLY_OVERVIEW = 'FETCHED_DOCTOR_MONTHLY_OVERVIEW';
export let FETCH_DOCTOR_MONTHLY_OVERVIEW_FAILED = 'FETCH_DOCTOR_MONTHLY_OVERVIEW_FAILED';
export let FETCH_DOCTOR_DAILY_OVERVIEW_ASYNC = 'FETCH_DOCTOR_DAILY_OVERVIEW_ASYNC';
export let FETCHED_DOCTOR_DAILY_OVERVIEW = 'FETCHED_DOCTOR_DAILY_OVERVIEW';
export let FETCH_DOCTOR_DAILY_OVERVIEW_FAILED = 'FETCH_DOCTOR_DAILY_OVERVIEW_FAILED';
export let SAVE_APPOINTMENT_REQUEST = 'SAVE_APPOINTMENT_REQUEST';

export let tryLogin = (email, password) => ({type: TRY_LOGIN, payload: {email, password}});

export let loginAsync = (getIdentityResponse, isNewLogin) => ({
    type: LOGIN_ASYNC,
    payload: {
        email: getIdentityResponse.email,
        authorities: getIdentityResponse.authorities,
        publicSessionId: getIdentityResponse.publicSessionId,
        isImpersonating: getIdentityResponse.isImpersonating,
        userReferenceId: getIdentityResponse.userReferenceId,
        userId: getIdentityResponse.userId,
        isNewLogin,
    }
});

export let login = (email, authorities, publicSessionId, isImpersonating, userId) => ({
    type: LOGIN,
    payload: {email, authorities, publicSessionId, isImpersonating, userId}
});

export let tryStopImpersonating = () => ({type: TRY_STOP_IMPERSONATING});
export let tryLogout = (useReturnPath = true) => ({type: TRY_LOGOUT, payload: {useReturnPath}});
export let logout = (useReturnPath = true) => ({type: LOGOUT, payload: {useReturnPath}});

export let fetchPatientProfileAsync = (async = true) => ({type: FETCH_PATIENT_PROFILE_ASYNC, payload: {async}});
export let fetchSoPatientProfileAsync = () => ({type: FETCH_SO_PATIENT_PROFILE_ASYNC});
export let fetchedPatientProfile = (profile) => ({type: FETCHED_PATIENT_PROFILE, payload: profile});

export let clearOngoingReservations = () => ({type: CLEAR_ONGOING_RESERVATIONS});
export let fetchOngoingReservations = () => ({type: FETCH_ONGOING_RESERVATIONS_ASYNC});
export let fetchedOngoingReservations = (ongoingReservations) => ({
    type: FETCHED_ONGOING_RESERVATIONS,
    payload: ongoingReservations
});

export let fetchDoctorProfileAsync = () => ({type: FETCH_DOCTOR_PROFILE_ASYNC});
export let fetchedDoctorProfile = (profile) => ({type: FETCHED_DOCTOR_PROFILE, payload: profile});

export let fetchDoctorMonthlyOverviewAsync = () => ({type: FETCH_DOCTOR_MONTHLY_OVERVIEW_ASYNC});
export let fetchedDoctorMonthlyOverview = (stats) => ({type: FETCHED_DOCTOR_MONTHLY_OVERVIEW, payload: {stats}});

export let fetchDoctorDailyOverviewAsync = () => ({type: FETCH_DOCTOR_DAILY_OVERVIEW_ASYNC});
export let fetchedDoctorDailyOverview = (stats) => ({type: FETCHED_DOCTOR_DAILY_OVERVIEW, payload: {stats}});

export let saveAppointmentRequest = (timeslots, comment) => ({
    type: SAVE_APPOINTMENT_REQUEST,
    payload: {timeslots, comment}
});

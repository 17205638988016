import React from "react";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {RichText} from "@commons/infra/storyblok/richText";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";
import {SectionWrapper} from "@commons/infra/storyblok/components/SectionWrapper";

export let QaGameSponsorsSection = ({blok}) => {
    return <SectionWrapper differentColor={blok.IsDifferentColorSection}>
        <QaGameSponsorsSectionInner
            title={blok.Title}
            text={<RichText content={blok.Content}/>}
            buttonText={blok.ButtonText}
            buttonLink={parseI18nLink(blok.ButtonLink.url)}
        />
    </SectionWrapper>
}

export let QaGameSponsorsSectionInner = ({title, text, buttonText, buttonLink}) => {
    return <div className={"bg-info bg-opacity-10 rounded-4 py-lg-4"}>
        <div className={"row"}>
            <div className={"col-xl-6 px-4 px-lg-6 py-3 py-xl-5 pt-4 pt-lg-5"}>
                <div className={"row g-3"}>
                    <SponsorCard imgSrc={"/img/qagame/myfitness.png"}/>
                    <SponsorCard imgSrc={"/img/qagame/internetaptieka.png"}/>
                    <SponsorCard imgSrc={"/img/qagame/balcia.png"}/>
                    <SponsorCard imgSrc={"/img/qagame/espa.png"}/>
                    <SponsorCard imgSrc={"/img/qagame/medon.png"}/>
                    <SponsorCard imgSrc={"/img/qagame/mysport.png"}/>
                    <SponsorCard className={"offset-lg-2"} imgSrc={"/img/qagame/sibiotech.png"}/>
                    <SponsorCard imgSrc={"/img/qagame/silyo.png"}/>
                </div>
            </div>

            <div className={"col-xl-5 offset-xl-1 px-5 pt-4 pb-4 py-xl-5 ps-xl-0 pe-xl-6"}>
                <h2>
                    {title}
                </h2>
                <p>
                    {text}
                </p>
                <LocalizedLink to={buttonLink} className={"btn btn-primary"}>
                    {buttonText}
                </LocalizedLink>
            </div>
        </div>
    </div>
}

let SponsorCard = ({imgSrc, imgAlt, className}) => {
    return <div className={`col-6 col-lg-4 ${className}`}>
        <div className={"p-3 d-flex align-items-center justify-content-center bg-white rounded-2"} style={{height: 90}}>
            <img src={imgSrc} alt={imgAlt}/>
        </div>
    </div>
}

import {useCallback, useEffect, useState} from "react";
import {useDispatch, useStore} from "react-redux";
import {tryLogout, tryStopImpersonating} from "@commons/domain/redux/actions";
import {setLoggedOutEvent} from "@commons/infra/helpers";
import {toast} from "react-toastify";
import {ErrorToast} from "@commons/infra/component/Toast";
import {useImmer} from "use-immer";

/**
 * Taken from Around theme js
 */
export const useUploadFromAroundTheme = () => {
    useEffect(() => {
        let fileArea = document.querySelectorAll('.file-drop-area');

        for (let i = 0; i < fileArea.length; i++) {
            let input = fileArea[i].querySelector('.file-drop-input'),
                message = fileArea[i].querySelector('.file-drop-message'),
                icon = fileArea[i].querySelector('.file-drop-icon'),
                button = fileArea[i].querySelector('.file-drop-btn');

            button.addEventListener('click', function () {
                input.click();
            });

            input.addEventListener('change', function () {
                if (input.files && input.files[0]) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        let fileData = e.target.result;
                        let fileName = input.files[0].name;
                        message.innerHTML = fileName;

                        if (fileData.startsWith('data:image')) {

                            let image = new Image();
                            image.src = fileData;

                            image.onload = function () {
                                icon.className = 'file-drop-icon file-drop-preview';
                                icon.innerHTML = '<img class="img-thumbnail rounded" src="' + image.src + '" alt="' + fileName + '">'
                                console.log(this.width);
                            }

                        } else if (fileData.startsWith('data:video')) {
                            icon.innerHTML = '';
                            icon.className = '';
                            icon.className = 'file-drop-icon ai-video';

                        } else {
                            icon.innerHTML = '';
                            icon.className = '';
                            icon.className = 'file-drop-icon ai-file-text';
                        }
                    }
                    reader.readAsDataURL(input.files[0]);
                }
            });
        }
    });
}

export const useStickyHeader = () => {
    useEffect(() => {

        let navbar = document.querySelector('.navbar-sticky');

        if (navbar == null) return;

        let navbarClass = navbar.classList,
            navbarH = navbar.offsetHeight,
            scrollOffset = 50;

        if (navbarClass.contains('navbar-floating') && navbarClass.contains('navbar-dark')) {
            window.addEventListener('scroll', (e) => {
                if (e.currentTarget.pageYOffset > scrollOffset) {
                    navbar.classList.remove('navbar-dark');
                    navbar.classList.add('navbar-light', 'navbar-stuck');
                } else {
                    navbar.classList.remove('navbar-light', 'navbar-stuck');
                    navbar.classList.add('navbar-dark');
                }
            });
        } else if (navbarClass.contains('navbar-floating') && navbarClass.contains('navbar-light')) {
            window.addEventListener('scroll', (e) => {
                if (e.currentTarget.pageYOffset > scrollOffset) {
                    navbar.classList.add('navbar-stuck');
                } else {
                    navbar.classList.remove('navbar-stuck');
                }
            });
        } else {
            window.addEventListener('scroll', (e) => {
                if (e.currentTarget.pageYOffset > scrollOffset) {
                    // document.body.style.paddingTop = navbarH + 'px';
                    navbar.classList.add('navbar-stuck');

                    // Hotfix for dark navbar
                    if (navbar.classList.contains('navbar-dark')) {
                        navbar.classList.add('navbar-ex-dark');

                        navbar.classList.remove('navbar-dark');
                        navbar.classList.add('navbar-light');
                    }
                } else {
                    document.body.style.paddingTop = '';
                    navbar.classList.remove('navbar-stuck');

                    // Hotfix for dark navbar
                    if (navbar.classList.contains('navbar-ex-dark')) {
                        navbar.classList.remove('navbar-ex-dark');

                        navbar.classList.add('navbar-dark');
                        navbar.classList.remove('navbar-light');
                    }
                }
            });
        }
    });
}


export const DEFAULT_ERROR_CODE = 'DEFAULT_CODE';

export const useValidationErrors = () => {

    let [errors, setErrors] = useState({})
    let [unexpectedError, setUnexpectedError] = useState(false)

    let clearErrors = () => {
        setErrors({});
        setUnexpectedError(false);
    }

    let clearError = (fieldName) => {
        let newErrors = {...errors};
        delete newErrors[fieldName]
        setErrors(newErrors)
    }

    let hasError = (fieldName, code = null) => {
        let hasError = fieldName in errors
        if (!hasError || code == null) return hasError
        return errors[fieldName].code === code
    }
    let hasErrors = () => Object.keys(errors).length > 0
    let getErrors = () => errors

    // be careful - setErrors is async and calling this method twice in a row will not work
    let addError = (fieldName, code = null) =>
        setErrors({...errors, [fieldName]: {code: code ? code : DEFAULT_ERROR_CODE}})

    let inputClass = (fieldName, code = null) => hasError(fieldName, code) ? 'is-invalid' : ''
    let blockClass = (fieldName, code = null) => hasError(fieldName, code) ? 'd-block' : 'd-none'

    return {
        clearErrors,
        clearError,
        hasError,
        hasErrors,
        getErrors,
        setErrors,
        addError,
        inputClass,
        blockClass,
        isUnexpectedError: unexpectedError,
        setUnexpectedError: () => setUnexpectedError(true),
        assignFromError: (e) => {
            clearErrors();

            if (!e.response || e.response.status !== 400 || !e.response.data.errors) {
                setUnexpectedError(true);
                return false;
            }

            let errors = {}
            e.response.data.errors.map(e => errors[e.fieldName] = {code: e.code ? e.code : DEFAULT_ERROR_CODE})
            setErrors(errors);
            return true;
        }
    }
}

export let getDomainErrorCodeFromError = (e) => {
    if (!e.response || e.response.status !== 400) {
        return
    }
    return e.response.data.code
}

export const useDomainErrors = () => {
    let [errors, setErrors] = useState({})
    let clearErrors = () => setErrors({})
    let clearError = (code) => {
        if (code in errors) {
            delete errors[code]
        }
        setErrors(errors)
    }

    let hasError = (code) => {
        return code in errors
    }
    let hasErrors = () => Object.keys(errors).length > 0
    let getErrors = () => errors

    let addError = (code) =>
        setErrors({...errors, [code]: true})

    let inputClass = (code) => hasError(code) ? 'is-invalid' : ''
    let blockClass = (code) => hasError(code) ? 'd-block' : 'd-none'

    return {
        clearErrors,
        clearError,
        hasError,
        hasErrors,
        getErrors,
        addError,
        inputClass,
        blockClass,
        assignFromError: (e) => {
            if (!e.response || e.response.status !== 400)
                return false;

            let errors = {}
            errors[getDomainErrorCodeFromError(e)] = true;
            setErrors(errors);
            return true;
        }
    }
}

export let useLogoutHandler = () => {
    let state = useStore().getState()
    let dispatch = useDispatch()

    return () => {
        if (state.authentication.isImpersonating) {
            dispatch(tryStopImpersonating())
        } else {
            setLoggedOutEvent()
            dispatch(tryLogout())
        }
    }
}

export let useUnstructuredState = (fieldsToInit) => {
    let [state, updateState] = useImmer(() => {
        let initState = {};
        fieldsToInit.map(field => {
            initState[field] = null
        });
        return initState;
    })

    return {
        value: (fieldName) => state[fieldName],
        setter: (fieldName) => {
            if (!state.hasOwnProperty(fieldName)) {
                toast(<ErrorToast text={`no field ${fieldName}`}/>)
                return;
            }
            return useCallback((value) => {
                updateState((state) => {
                    state[fieldName] = value
                })
            }, [fieldName, updateState])
        },
        arraySetter: (fieldName) => {
            if (!state.hasOwnProperty(fieldName)) {
                toast(<ErrorToast text={`no field ${fieldName}`}/>)
                return;
            }
            return useCallback((optionName, value) => {
                updateState((state) => {
                    if (value) {
                        state[fieldName] = [...state[fieldName] || [], optionName]
                    } else {
                        state[fieldName] = state[fieldName].filter(on => on !== optionName)
                    }
                })
            }, [fieldName, updateState])
        },
        set: (fieldName, value) => {
            updateState((state) => {
                state[fieldName] = value
            })
        },
        initialize: (newState) => {
            updateState((state) => {
                for (const [key, value] of Object.entries(newState)) {
                    state[key] = value
                }
            })
        },
        whole: () => state
    }
}
import React, {useEffect, useState} from "react";
import Loading from "@commons/ui/component/Loading";
import {QaGameApi} from "../QaGameApi";
import {RadioButtons} from "@commons/ui/component/form/Radio";
import {ErrorDescription} from "@commons/uinew/text/validation";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {QaGameLinks} from "../QaGameLinks";
import {Checkbox} from "@commons/ui/component/form/Checkbox";
import {useValidationErrors} from "@commons/ui/hooks";
import {qagameTermsAndConditionsUrl} from "../helpers";
import {FormButtonsContainer, FormProceedButton} from "@commons/uinew/component/forms";
import {TARGET_BLANK_PROPS_INTERNAL} from "@commons/infra/helpers";

let PlayPage = () => {
    let navigate = useNavigate()
    let validationErrors = useValidationErrors()

    let [entry, setEntry] = useState()
    let [isLoading, setIsLoading] = useState(true)
    let [isButtonLoading, setIsButtonLoading] = useState(false)

    let [currentQuestionIdx, setCurrentQuestionIdx] = useState(0)
    let [selectedAnswer, setSelectedAnswer] = useState(null)
    let [totalQuestions, setTotalQuestions] = useState(null)
    let [answers, setAnswers] = useState({})
    let [showEmptyAnswerError, setShowEmptyAnswerError] = useState()

    let [areTermsAccepted, setAreTermsAccepted] = useState(false)

    let isLastQuestion = () => currentQuestionIdx + 1 === totalQuestions

    let shouldAcceptTerms = currentQuestionIdx === 0

    let onNextQuestion = () => {
        if (selectedAnswer === null) {
            setShowEmptyAnswerError(true)
            return
        }
        setShowEmptyAnswerError(false)

        if (shouldAcceptTerms && !areTermsAccepted) {
            validationErrors.addError("areTermsAccepted")
            return
        }

        let newAnswers = {...answers, [entry.questions[currentQuestionIdx].id]: selectedAnswer};
        setAnswers(newAnswers)
        setSelectedAnswer(null)

        if (isLastQuestion()) {
            completedTest(newAnswers)
            return;
        }

        setSelectedAnswer(null)
        setCurrentQuestionIdx(currentQuestionIdx + 1)
    }

    let completedTest = (answers) => {
        setIsButtonLoading(true)
        QaGameApi.participate(answers)
            .then(r => r.data)
            .then(d => {
                navigate(QaGameLinks.results(
                    d.totalAnswers,
                    d.correctAnswers,
                    d.prizeId,
                    d.pityPrize,
                    d.codeValue,
                    d.promoCodeDiscountPercent,
                    d.prizeTexts.prizeName,
                    d.prizeTexts.prizeUsage,
                ))
            })
    }

    useEffect(() => {
        QaGameApi.initEntry()
            .then(r => r.data)
            .then(d => {
                setEntry(d)
                setIsLoading(false)
                setTotalQuestions(d.questions && d.questions.length)
            })
    }, []);

    return <div className={"container pt-5"}>
        <div className={"row"}>

            <div className={"col-xl-6"}>
                <h1>Veselības zināšanu tests</h1>
                <p>Pārbaudi savas zināšanas par veselību, atbildot uz 3 jautājumiem, un saņem kādu vērtīgu balvu
                    no&nbsp;
                    <span className={"fw-medium"}>Medon</span>,&nbsp;
                    <span className={"fw-medium"}>MyFitness</span>,&nbsp;
                    <span className={"fw-medium"}>InternetAptieka.lv</span>,&nbsp;
                    <span className={"fw-medium"}>Balcia</span>,&nbsp;
                    <span className={"fw-medium"}>ESPA Riga</span>,&nbsp;
                    <span className={"fw-medium"}>MySport</span>,&nbsp;
                    <span className={"fw-medium"}>Síbiotech</span>
                    &nbsp;vai&nbsp;
                    <span className={"fw-medium"}>SiLYO</span>
                    .</p>

                {isLoading && <Loading/>}

                <div className={"bg-white rounded-4 p-3 p-xl-4 my-xl-5 my-4"}>
                    {!isLoading && <>
                        {!entry.canParticipateToday && <>
                            <h2>Gaidīsim Tevi spēlē rīt!</h2>
                        </>}

                        {entry.canParticipateToday && <>
                            <p>
                                {currentQuestionIdx + 1} / {totalQuestions} jautājums
                            </p>
                            <QuestionAndAnswers
                                selectedAnswer={selectedAnswer}
                                setSelectedAnswer={setSelectedAnswer}
                                currentQuestion={entry.questions[currentQuestionIdx]}
                            />

                            {showEmptyAnswerError && <ErrorDescription>
                                Lūdzu, norādi atbildi
                            </ErrorDescription>}

                            {shouldAcceptTerms && <div className={"mb-3"}>
                                <Checkbox
                                    label={<FormattedMessage
                                        id={"pages.qa-game.i-agree-with-terms"}
                                        values={{
                                            link: chunk => <a
                                                href={qagameTermsAndConditionsUrl()} {...TARGET_BLANK_PROPS_INTERNAL}>{chunk}</a>
                                        }}
                                    />}
                                    value={areTermsAccepted}
                                    onChange={setAreTermsAccepted}
                                    validationErrors={validationErrors}
                                    id={"areTermsAccepted"}
                                    errorField={'areTermsAccepted'}
                                    errorMessage={'pages.registration.patient.step-1.i-accept-terms-and-conditions.error.default'}
                                />
                            </div>}

                            <FormButtonsContainer>
                                <FormProceedButton
                                    className={"btn btn-primary btn-lg"}
                                    onClick={onNextQuestion}
                                    isLoading={isButtonLoading}>

                                    <FormattedMessage id={"common.button.continue"}/>
                                </FormProceedButton>
                            </FormButtonsContainer>
                        </>}
                    </>}
                </div>
            </div>

            <div className={"offset-xl-1 col-xl-5 d-none d-xl-block align-items-center align-content-center"}>
                <img alt={""}
                     src={"https://a.storyblok.com/f/297231/420x582/e7951600ae/davanas-no-myfitness-balcia-espa.png"}/>
            </div>

        </div>
    </div>
}

let QuestionAndAnswers = ({currentQuestion, setSelectedAnswer, selectedAnswer}) => {
    let {formatMessage} = useIntl()

    return <>
        <h5>
            <FormattedMessage id={`qa-game.item.${currentQuestion.id}.question.text`}/>
        </h5>
        <p>
            <RadioButtons
                containerClassName={"flex-column gap-2"}
                onChange={(v) => setSelectedAnswer(v)}
                selectedValue={selectedAnswer}
                options={currentQuestion.answerOptions.map((ao) => ({
                    elementId: ao.id,
                    value: ao.id,
                    label: formatMessage({id: `qa-game.item.${currentQuestion.id}.answers.${ao.id}.text`})
                }))}
                textualContent={true}
            />
        </p>
    </>
}

export default PlayPage
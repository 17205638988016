import React from "react";

export let GenericSection1 = ({title, text, imgSrc, imgAlt, imgWidth, imgHeight, imgSideClassName}) => {
    return <div class="row flex-grow-1">
        <div class="col-md-6 col-xl-6 order-1 order-lg-0 align-content-center">
            <TextSide title={title} text={text}/>
        </div>

        <div
            class="col-md-6 offset-xl-1 col-xl-5 offset-xl-0 pb-2 pb-sm-0 mb-4 mb-sm-5 mb-md-0 order-0 order-lg-1 ps-lg-0 px-lg-0 px-3 d-none d-lg-block">
            <ImgSide
                imgSrc={imgSrc}
                imgAlt={imgAlt}
                imgWidth={imgWidth}
                imgHeight={imgHeight}
                className={imgSideClassName}
            />
        </div>
    </div>
}

export let TextSide = ({title, text}) => {
    return <>
        <h3 className="h2 mb-lg-4">
            {title}
        </h3>
        <p className="mb-0">
            {text}
        </p>
    </>
}

export let ImgSide = ({imgSrc, imgAlt, imgWidth, imgHeight, maxHeight, maxWidth, className = ""}) => {
    return <>
        <div className={`${className} d-flex justify-content-center pt-3`}>
            <img className="d-block position-relative z-2"
                 src={imgSrc}
                 alt={imgAlt}
                 width={imgWidth}
                 height={imgHeight}
                 style={{maxHeight, maxWidth}}
            />
        </div>
    </>
}
import {SmallerHero} from "@commons/uinew/component/heading";
import React from "react";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";
import {RichText} from "@commons/infra/storyblok/richText";

let HeroWithPictureSection = ({blok}) => {
    return <SmallerHero
        // backgroundClass={blok.BackgroundClass}
        backgroundClass={'bg-dark'}
        imgSrc={blok.Picture.filename}
        imgAlt={blok.Picture.alt}
        imageHorizontalAlign={blok.ImageHorizontalAlign}
        title={blok.Title}
        text={<RichText content={blok.Text}/>}
        buttonText={blok.ButtonText}
        buttonLink={parseI18nLink(blok.ButtonLink.url)}
        dark={true}
    />
}

export default HeroWithPictureSection

import {useEffect, useState} from "react";

export let RadioButtons = ({
                               containerClassName,
                               options,
                               selectedValue,
                               disabled,
                               onChange,
                               textualContent = false
                           }) => {
    let [selectedLabel, setSelectedLabel] = useState()
    useEffect(() => {
        let selected = options.find(o => o.value === selectedValue)
        if (selected) {
            setSelectedLabel(selected.label)
        }
    }, [options]);

    return <>
        <div class={`d-flex pdf--hide ${containerClassName}`}>
            {options.map((option) => <div class="me-2">
                <input type="radio"
                       className={`btn-check`}
                       id={option.elementId}
                       value={option.value}
                       disabled={disabled}
                       checked={option.value === selectedValue}
                       onChange={(e) => onChange(e.target.value)}/>
                <label htmlFor={option.elementId}
                       className={`btn btn-outline-secondary px-2 ${textualContent ? 'w-100 text-wrap' : ''}`}>
                    <span class="mx-1">{option.label}</span>
                </label>
            </div>)}
        </div>
        <div className={"pdf--d-inline-block"}>
            {selectedLabel}
        </div>
    </>
}